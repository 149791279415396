import React, { FC, useState, useEffect, useRef } from "react";
import { MessageInput } from '@chatscope/chat-ui-kit-react';

interface TextChatInputProps {
  sendDisabled: React.MutableRefObject<boolean>,
  disabled: boolean,
  isTyping: boolean,
  handleMessageSend: (userMessage: string, firstMessage?: boolean, isVoiceMessage?: boolean) => Promise<any>,
}

const TextChatInput: FC<TextChatInputProps> = (props) => {
  const { sendDisabled, disabled, isTyping, handleMessageSend } = props;
  const inputRef = useRef(null);
  const [messageVal, setMessageVal] = useState<string>("");

  useEffect(() => {
    inputRef?.current?.focus();
  }, [isTyping]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MessageInput
      id='text-input-chat-message'
      sendDisabled={sendDisabled.current}
      ref={inputRef}
      value={messageVal}
      disabled={disabled}
      onChange={(val) => {
        sendDisabled.current = val.length === 0;
        setMessageVal(val)
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && sendDisabled.current && !e.shiftKey) {
          e.preventDefault();
        }
      }}
      onPaste={(e) => {
        e.preventDefault();
        if (e.clipboardData) {
          let content = e.clipboardData.getData('text/plain');
          sendDisabled.current = (messageVal + content).length === 0;

          setMessageVal(messageVal + content);
        }
      }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '0.6rem',
        paddingTop: '0.6rem',
        paddingBottom: '0.6rem',
        width: '60px',
        flexGrow: '1',
      }}
      placeholder='Message'
      attachButton={false}
      onSend={(innerHtml: string, textContent: string) => {
        // Check if AI is typing
        if (isTyping) {
          // Show a message or do nothing
          console.log('AI is typing. Please wait.');
          return;
        }

        // Continue with sending the message logic
        if (textContent?.trim()) {
          handleMessageSend(textContent?.trim());
          sendDisabled.current = true;
          setMessageVal('');
        }
      }}
    />
  )
}

export default TextChatInput;
