import React, { useState, useRef } from 'react';
import { Notifications } from '@mui/icons-material';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  Paper,
  Badge,
  Box,
  Menu,
  MenuItem,
  Avatar,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import SoopraIcon from '../../assets/images/soopra-icon.png';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { getNotifications, markNotificationRead } from '../../utils/api';
import { TypingIndicator } from '@chatscope/chat-ui-kit-react';
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const NotificationMenu = () => {
  const [openNotificationDrawer, setNotificationDrawer] = useState(false);
  const [alignment, setAlignment] = React.useState('all');
  const { authUser, authLoading } = useAuth();
  const buttonRef = useRef(null);
  const pageNum = useRef(0);
  const fullyLoaded = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterToggle = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      if (newAlignment === 'unread') {
        handleShowUnreadNotifications();
      } else {
        handleShowAllNotifications();
      }
      setAlignment(newAlignment);
    }
  };

  const toggleNotificationDrawer = () => {
    setNotificationDrawer(!openNotificationDrawer);
  };

  // Boolean state variable to show all notifications vs unread ones
  const [showAllNotifications, setShowAllNotifications] = useState(true);
  const handleShowAllNotifications = () => {
    setShowAllNotifications(true);
  };
  const handleShowUnreadNotifications = () => {
    setShowAllNotifications(false);
  };

  /**
   * @description Function calculates the time elapsed since given timestamp
   * @param timestamp The timestamp to get the time ago for. It should be standard unix timestamp without milliseconds
   * @returns A string representing the time ago.
   */
  const getTimestampAgo = (timestamp) => {
    const timeAgo = new Date().getTime() - new Date(timestamp * 1000).getTime();

    const minutes = Math.round(timeAgo / (1000 * 60));
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);
    const weeks = Math.round(days / 7);
    const months = Math.round(weeks / 4);

    if (minutes < 1) {
      return 'Just now';
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else if (days < 7) {
      return `${days} days ago`;
    } else if (weeks < 4) {
      return `${weeks} weeks ago`;
    } else {
      return `${months} months ago`;
    }
  };

  const eventTracker = useAnalyticsEventTracker();

  /**
   * @function NotificationText
   * @description Renders a notification text component.
   * @param {Object} item The notification item.
   * @returns {ReactElement} The notification text component.
   */
  const NotificationText = (item) => (
    <Box sx={{ cursor: 'pointer' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: 'auto',
          mt: 1,
        }}
      >
        <Typography // Title
          variant='subtitle1'
          color={item.is_read ? 'text.secondary' : 'text.primary'}
          sx={{
            fontWeight: 600,
            lineHeight: 1.5,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 150,
          }}
        >
          {item.title}
        </Typography>

        <Typography // Relative Date
          variant='body2'
          color='text.secondary'
        >
          {getTimestampAgo(item.timestamp)}
        </Typography>
      </Box>

      <Typography // Body
        variant='body2'
        color={item.is_read ? 'text.secondary' : 'text.primary'}
        sx={{ fontWeight: 400, mb: 1 }}
      >
        {item.text}
      </Typography>
    </Box>
  );

  /**
   * @description Renders a notification item in the notification bar.
   * @param item The notification item to render.
   * @returns A React element representing the notification item.
   */
  const renderNotificationItem = (item) => (
    <ListItem
      key={item.notification_id}
      onClick={() => {
        markNotificationAsRead(item.notification_id);
        toggleNotificationDrawer();
      }}
      divider
      sx={{
        borderColor: 'dashed',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        ml: 1,
      }}
    >
      <Badge
        overlap='circular'
        badgeContent={''}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        color={'mainBlue'}
        sx={{
          mr: 2,
          mt: 1.5,
          color: 'blue',
          '& .MuiBadge-badge': {
            width: '12px',
            height: '12px',
            borderRadius: '50%',
          },
        }}
        variant='dot'
        invisible={item.is_read}
      >
        <Avatar
          src={item.icon && item.icon !== '' ? item.icon : SoopraIcon}
          sx={{ ml: 0.5 }}
        ></Avatar>
      </Badge>
      {item.link_to && item.link_to !== '' ? (
        <Link id='href-notification-link' to={item.link_to}>
          NotificationText(item)
        </Link>
      ) : (
        NotificationText(item)
      )}
    </ListItem>
  );

  /**
   * @description Renders a list of notification items in the notification bar based on
   * state of showAllNotifications
   * @returns A React element representing the notification item list.
   */
  const renderNotifications = () => {
    if (showAllNotifications) {
      return items.map(renderNotificationItem);
    } else {
      let filteredItems = items.filter((item) => !item.is_read);
      if (filteredItems.length > 0) {
        return filteredItems.map(renderNotificationItem);
      } else {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              pt: 5,
            }}
          >
            <Typography color={'text.primary'}>
              You have no unread messages
            </Typography>
          </Box>
        );
      }
    }
  };

  /**
   * @description Marks the notification with givenId as read
   * @param itemId The notification_id of the notification to be marked as read.
   */
  const markNotificationAsRead = (itemId) => {
    const item = items.find((item) => item.notification_id === itemId);
    if (!item.is_read) {
      item.is_read = true;
      setItems([...items]);
      markNotificationAsReadAPICall(authUser, item.notification_id);
    }
  };

  /**
   * Marks all unread notifications in the items array as read by updating their is_read attribute to true.
   * Also triggers an API call to update the notifications DB.
   */
  const markAllNotificationsAsRead = () => {
    let updatedItems = items.slice();
    for (let item of updatedItems) {
      if (!item.is_read) {
        item.is_read = true;
        markNotificationAsReadAPICall(authUser, item.notification_id);
      }
    }
    setItems(updatedItems);
  };

  /**
   * @description Renders a notification icon with a badge indicating the number of unread notifications.
   * @param count The number of unread notifications.
   * @returns A React element representing the notification icon with badge.
   */
  const NotificationIconWithBadge = ({ count }) => {
    return (
      <IconButton
        id='button-toggle-notification-menu'
        ref={buttonRef}
        edge='start'
        color='inherit'
        aria-label='notifications'
        onClick={toggleNotificationDrawer}
      >
        <Badge
          badgeContent={''}
          color='error'
          invisible={count === 0}
          variant='dot'
        >
          <Notifications />
        </Badge>
      </IconButton>
    );
  };

  const handleScroll = (e: any) => {
    var node = e.target;
    const bottom = node.scrollHeight - node.scrollTop - node.clientHeight < 10;
    if (bottom && !isLoading) {
      getNotificationsFromAPI(authUser, pageNum.current+1, 10)

    }
  }

  const getNotificationsFromAPI = (authUser: any, page?: number, pageSize?: number) => {
    if (authUser && !fullyLoaded.current) {
      setIsLoading(true);
      getNotifications(authUser?.documentId, page, pageSize)
        .then(async (response: any) => {
          if (response?.status === 200) {
            const messageResp = await response.json();
            if (messageResp['notifications'] && messageResp['notifications'].length){
              setItems((prevData) => [...prevData, ...messageResp['notifications']]);
              pageNum.current = pageNum.current + 1;
              if (pageSize && messageResp['notifications'].length < pageSize){
                fullyLoaded.current = true;
              }

            } else {
              // stop fetching once all notifications are loaded
              fullyLoaded.current = true;
            }
          }
          setIsLoading(false);
          eventTracker('get-notifications');
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          eventTracker('get-notifications failed');
        });
    }
  };

  const markNotificationAsReadAPICall = (authUser, notification_id) => {
    markNotificationRead(authUser?.documentId, notification_id)
      .then(async (response) => {
        if (response?.status === 200) {
          // const messageResp = await response.json();
        }
        eventTracker('mark-notifications-read');
      })
      .catch((error) => {
        console.log(error);
        eventTracker('mark-notifications-read failed');

      });
  };

  //Function used only for testing
  const getTestNotifications = () => {
    setItems((prevData) => [...prevData, ...testNotifications]);
  };

  React.useEffect(() => {
    if (!authLoading) getNotificationsFromAPI(authUser, 1, 10);
    //         getTestNotifications()
  }, [authUser, authLoading]);

  const [items, setItems] = useState([]);

  interface Notification {
    content: string;
    is_read: boolean;
    timestamp: number; // UNIX timestamp
    notification_id: string;
    link_to?: string;
    user_id: string;
  }
  const testNotifications: Array<Notification> = [
    {
      content: 'Your chat streak with Srinivas is about to end',
      is_read: false,
      timestamp: 1691797807,
      notification_id: 'abcd1',
      link_to: '/srinivas/chat',
      user_id: 'kfQ6MrLEdfajFo2zxU2vAk2P5Kf1',
    },
    {
      content: 'Your chat streak with Galina ends soon',
      is_read: false,
      timestamp: 1691698404,
      notification_id: 'abcd2',
      link_to: 'galinatest2/chat',
      user_id: 'kfQ6MrLEdfajFo2zxU2vAk2P5Kf1',
    },
    {
      content: 'Some of your chat streaks will end soon',
      is_read: false,
      timestamp: 1691698044,
      notification_id: 'abcd3',
      link_to: '/creators',
      user_id: 'kfQ6MrLEdfajFo2zxU2vAk2P5Kf1',
    },
    {
      content: 'Some of your chat streaks are about to end',
      is_read: true,
      timestamp: 1601559580,
      notification_id: 'abcd4',
      link_to: '/creators',
      user_id: 'kfQ6MrLEdfajFo2zxU2vAk2P5Kf1',
    },
    {
      content:
        'Jason, an attorney who specializes in rental conflicts is here. Do you have any questions for him?',
      is_read: false,
      timestamp: 1601559580,
      notification_id: 'abcd5',
      link_to: '',
      user_id: 'kfQ6MrLEdfajFo2zxU2vAk2P5Kf1',
    },
    {
      content:
        'Tara, has new content updated. Do you wanna know about her latest updates?',
      is_read: false,
      timestamp: 1601559580,
      notification_id: 'abcde',
      link_to: '',
      user_id: 'kfQ6MrLEdfajFo2zxU2vAk2P5Kf1',
    },
    {
      content:
        'Hurray!! The new content you have uploaded has been added to indexes.',
      is_read: true,
      timestamp: 1601559580,
      notification_id: 'abcdg',
      link_to: null,
      user_id: 'kfQ6MrLEdfajFo2zxU2vAk2P5Kf1',
    },
    {
      content:
        'Tara, has new content updated. Do you wanna know about her latest updates???',
      is_read: false,
      timestamp: 1601559585,
      notification_id: 'abcdef',
      link_to: null,
      user_id: 'kfQ6MrLEdfajFo2zxU2vAk2P5Kf1',
    },
  ];

  return (
    <>
      <div >
        <NotificationIconWithBadge
          count={items.filter((item) => !item.is_read).length}
        />
      </div>
      <Drawer
        anchor='right'
        open={openNotificationDrawer}
        onClose={toggleNotificationDrawer}
        sx={{
          width: '350px',
        }}
        PaperProps={{
          style: {
            width: '350px',
          },
          onScroll: handleScroll
        }}
      >
        <div role='presentation'>
          <Paper sx={{ boxShadow: 'none' }}>
            <List sx={{ mr: 1, pt: 0 }}>
              <Box
                sx={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: 'white',
                  zIndex: 2,
                }}
              >
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    ml: 1,
                  }}
                >
                  <Typography variant='h5' sx={{ fontWeight: 600, pt: 1 }}>
                    Notifications
                  </Typography>
                  <Box sx={{ pt: 1 }}>
                    <IconButton
                      id='button-open-notification-menu'
                      onClick={handleMenuOpen}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id='notification-menu'
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          markAllNotificationsAsRead();
                          handleMenuClose();
                          toggleNotificationDrawer();
                        }}
                      >
                        Mark All Read
                      </MenuItem>
                    </Menu>
                    <IconButton
                      id='button-toggle-notification-menu'
                      onClick={toggleNotificationDrawer}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </ListItem>
                <ListItem divider sx={{ borderColor: 'dashed', ml: 1 }}>
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleFilterToggle}
                    aria-label='Platform'
                    sx={{
                      width: '100%',
                      backgroundColor: '#3EACEF',
                      borderRadius: 10,
                      mb: 2,
                    }}
                  >
                    <ToggleButton
                      id='button-toggle-all-notification'
                      value='all'
                      sx={{
                        px: 4,
                        backgroundColor: '#FFFFFF',
                        borderRadius: 10,
                        color: '#3EACEF',
                        border: '1px solid #3EACEF',
                        '&:hover': {
                          backgroundColor: '#FFFFFFE8',
                        },
                        '&.Mui-selected': {
                          color: '#FFFFFF',
                        },
                        boxShadow: 'none',
                        width: '50%',
                      }}
                    >
                      All
                    </ToggleButton>
                    <ToggleButton
                      id='button-toggle-unread-notification'
                      value='unread'
                      sx={{
                        px: 4,
                        backgroundColor: '#FFFFFF',
                        borderRadius: 10,
                        color: '#3EACEF',
                        border: '1px solid #3EACEF !important',
                        ml: '0px !important',
                        '&:hover': {
                          backgroundColor: '#FFFFFFE8',
                        },
                        '&.Mui-selected': {
                          color: '#FFFFFF',
                        },
                        boxShadow: 'none',
                        width: '50%',
                      }}
                    >
                      Unread
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ListItem>
              </Box>
              {renderNotifications()}
              {!fullyLoaded.current ?
                <Box sx={{display: 'flex', justifyContent: 'center'}} >
                  <TypingIndicator style={{ height: '30px' }} />
                </Box> :
                <></>}
            </List>
          </Paper>
        </div>
      </Drawer>
    </>
  );
};

export default NotificationMenu;
