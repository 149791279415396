import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import VerifyImage from '../../assets/images/verification-email.svg';
import VerifyImageWidget from '../../assets/images/verification-email-widget.svg'
import { useAuth } from '../../hooks/useAuth';
import TopImageSection from '../../component/TopImageSection';
import { handleVerifyEmail } from '../creatorOnBoarding/helper';
import { auth } from '../../utils/firebase';

interface VerifyEmailProps {
  isWidget?:boolean,
  email?:string,
  setShowVerifyEmail?: React.Dispatch<React.SetStateAction<boolean>>;
  setEmailExists?: React.Dispatch<React.SetStateAction<boolean>>;
  setEmail?: React.Dispatch<React.SetStateAction<string>>;
}

export default function VerifyEmail(props: VerifyEmailProps) {

  const { isWidget=false, email, setShowVerifyEmail, setEmailExists, setEmail} = props
  const { authUser, authLoading, logoutUser } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isWidget) {
      if (authLoading) {
        return;
      }
      if (authUser?.documentId && auth?.currentUser?.emailVerified) {
        if (!authUser?.userName) {
          navigate('/create-profile');
          return;
        }
        navigate('/creators');
      } else if (!authUser?.documentId) {
        toast.error('You are not authorized to access this location');
        navigate('/signin');
      }
    }
  }, [authUser, auth?.currentUser, authLoading]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleButtonClick = () => {
    const email = authUser?.email;
    logoutUser()
      .then(() => {
        if (isWidget) {
          setEmail(email)
          setEmailExists(true)
          setShowVerifyEmail(false)
        }
        else {
          navigate('/signin-email', { state: { email } });
        }
      })
      .catch((error) => {
        console.log('logout-error', error);
        toast.error('Something went wrong');
      });
  };

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      alignItems='center'
      justifyContent='flex-start'
      justifyItems='center'
      textAlign='center'
      width='100%'
      px={isWidget ? 0 : 2}
    >
      {authLoading ? (
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={40} />
        </Box>
      ) : (
        <Box
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          flexDirection='column'
          flex={0.5}
          width='100%'
          gap={isWidget ? 2 : 0}
        >
          {isWidget ? 
              <Box display='flex' flexDirection='column' gap={1}>
                <Box className='widget-common-header'>Verify Email</Box>
                <Box className='widget-verify-sub-header'>Please verify your email address to access this AI persona</Box>
                <Box><img className='widget-verify-placeholder-image' src={VerifyImageWidget} alt='widget-verify-placeholder-image'/></Box>
              </Box>
            : 
              <TopImageSection
                image={VerifyImage}
                title='Verify Your Email'
                titleProps={{
                  variant: 'h6',
                  fontSize: '16px',
                }}
                description='Please verify your email address to access Soopra.ai'
                descriptionProps={{
                  sx: {
                    maxWidth: '260px',
                  },
                }}
              />
          }

          <Typography
            sx={{ mt: isWidget ? 0 : -1, mb: isWidget ? 0 : 4 }}
            variant='subtitle2'
            fontWeight='600'
            fontSize={isWidget ? '19px' :'12px'}
            color={isWidget ? '#818181' : 'black'}
          >
            Email sent to:{' '}
            <Typography
              component='span'
              fontWeight='600'
              fontSize={isWidget ? '19px' :'12px'}
              color='themeBlue.main'
              sx={{
                textDecoration: isWidget ? 'underline' : 'none'
              }}
            >
              {authUser?.email || email || ''}
            </Typography>
          </Typography>

          {authUser?.email && (
            <>
              <Button
                variant='contained'
                // fullWidth
                sx={{
                  width: isWidget ? '100%':'80%',
                  maxWidth: isWidget ? '100%' : '500px',
                }}
                onClick={handleButtonClick}
              >
                {isWidget ? 'Next' :'Continue Login'}
              </Button>

              {isWidget ? 
                <></>
              :
                <Typography
                  sx={{ textDecoration: 'underline', mt: 5, cursor: 'pointer' }}
                  component='h5'
                  variant='h6'
                  fontWeight='600'
                  fontSize='0.8rem'
                  p={0.5}
                  onClick={handleVerifyEmail}
                >
                  Click Here To Resend Verification Email
                </Typography>
              }
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
