import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import UploadRounded from '@mui/icons-material/UploadRounded';
import { toast } from 'react-toastify';
import { auth } from '../../utils/firebase';
import { handleVerifyEmail } from './helper';
import { FormMode } from './signup.types';
import { SUPPORTED_IMAGE_MIME_TYPES } from '../../config/const';
import InputAdornment from '@mui/material/InputAdornment';

interface CreatorOnBoardingStepOneProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  setTouched: any;
  setErrors: any;
  isValid: any;
  dirty: any;
  formSubmitting: boolean;
  formMode: FormMode;
}

const AGE = [
  { value: '18-24', label: '18-24' },
  { value: '25-34', label: '25-34' },
  { value: '35-44', label: '35-44' },
  { value: '45-54', label: '45-54' },
  { value: '55-64', label: '55-64' },
  { value: '65-74', label: '65-74' },
  { value: '75-84', label: '75-84' },
  { value: '85+', label: '85+' },
];

const GENDER = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
  { value: 'Non-Binary', label: 'Non-Binary' },
  { value: 'Gender Fluid', label: 'Gender Fluid' },
  { value: 'A gender not listed', label: 'A gender not listed' },
  { value: 'Unsure how to describe', label: 'Unsure how to describe' },
  { value: 'Prefer not to say', label: 'Prefer not to say' },
];

export default function CreatorOnBoardingStepOne(
  props: CreatorOnBoardingStepOneProps
) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setTouched,
    formSubmitting,
    formMode,
  } = props;

  const onDrop = React.useCallback(
    async (acceptedFiles: any) => {
      const isValid = fileValidator(acceptedFiles[0]);
      if (!isValid) {
        toast.error('Please select a valid banner image');
        setTouched({ ...touched, bannerPhoto: true });
        setFieldValue('bannerPhoto', '');
      }
      if (isValid) {
        setFieldValue('bannerPhoto', acceptedFiles[0]);
      }
    },
    [touched, setTouched, setFieldValue]
  );

  const fileValidator = (file: any) => {
    if (!SUPPORTED_IMAGE_MIME_TYPES.includes(file?.type)) {
      return false;
    }
    return true;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    maxFiles: 1,
  });

  const avatarInput = React.useCallback(
    (values, touched, setTouched, setFieldValue) => {
      const profileUploaded =
        values?.profilePhoto &&
        typeof values?.profilePhoto === 'object' &&
        values?.profilePhoto?.name
          ? URL.createObjectURL(values?.profilePhoto)
          : values?.profilePhoto && typeof values?.profilePhoto === 'string'
          ? values?.profilePhoto
          : '';

      return (
        <Box
          border='1px solid #B3B3B3;'
          borderRadius='12px'
          display='flex'
          gap={1}
          flexDirection='column'
          alignItems='center'
          py={2}
          width='100%'
          position='relative'
        >
          <input
            type='file'
            accept='image/*,image/heif,image/heic'
            id='file-input-profile-photo'
            name='profilePhoto'
            hidden
            onChange={(event) => {
              setTouched({ ...touched, profilePhoto: true });
              setFieldValue('profilePhoto', event?.currentTarget?.files?.[0]);
            }}
          />
          <label htmlFor='file-input-profile-photo' style={{ cursor: 'pointer' }}>
            <Badge
              overlap='circular'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={
                <Box display='flex' alignItems='center' justifyContent='center'>
                  {profileUploaded ? (
                    <EditOutlinedIcon
                      sx={{
                        height: '16px',
                        width: '16px',
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        height: '20px',
                        width: '20px',
                      }}
                    />
                  )}
                </Box>
              }
              color='primary'
              sx={{
                '& .MuiBadge-badge': {
                  border: '2px solid #fff',
                  height: '28px',
                  width: '28px',
                  padding: '4px',
                  borderRadius: '50px',
                  backgroundColor: profileUploaded
                    ? '#A8A8A8'
                    : 'themeBlue.main',
                },
              }}
            >
              <Avatar
                alt={values?.fullName}
                src={profileUploaded}
                sx={{
                  width: 76,
                  height: 76,
                  fontSize: '2.375rem'
                }}
              >{values?.fullName ? values?.fullName[0] : ''}</Avatar>
            </Badge>
          </label>
          <Typography component='h5' variant='body2' color='#818181'>
            {profileUploaded ? 'Edit Avatar' : 'Add Avatar'}
          </Typography>
          <Typography
            variant='body2'
            color='rgba(0, 0, 0, 0.6)'
            sx={{
              position: 'absolute',
              top: '-10px',
              left: '10px',
              backgroundColor: '#FFFFFF',
              px: '6px',
              zIndex: 99,
              borderRadius: '6px',
            }}
          >
            Avatar
          </Typography>
        </Box>
      );
    },
    []
  );

  const bannerInput = React.useCallback(
    (values) => {
      const bannerUploaded =
        values?.bannerPhoto &&
        typeof values?.bannerPhoto === 'object' &&
        values?.bannerPhoto?.name
          ? URL.createObjectURL(values?.bannerPhoto)
          : values?.bannerPhoto && typeof values?.bannerPhoto === 'string'
          ? values?.bannerPhoto
          : '';

      return (
        <Box mb={2.5} width='100%' position='relative'>
          <Box
            {...getRootProps()}
            width='100%'
            height='150px'
            border='1px solid #B3B3B3;'
            borderRadius='12px'
            sx={{ cursor: 'pointer' }}
          >
            {bannerUploaded ? (
              <img
                src={bannerUploaded}
                alt='user-banner'
                height='100%'
                width='100%'
                style={{
                  borderRadius: '12px',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Box
                height='100%'
                width='100%'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                color='grey.500'
                gap={1}
              >
                <UploadRounded sx={{ fontSize: '40px' }} />
                <Typography color='grey.500' variant='body2'>
                  Choose a file or drag image here
                </Typography>
              </Box>
            )}
            <input id='file-input-banner-image' {...getInputProps()} />
            {isDragActive && <p>Drop the image here ...</p>}
            <Typography
              variant='body2'
              color='rgba(0, 0, 0, 0.6)'
              sx={{
                position: 'absolute',
                top: '-10px',
                left: '10px',
                backgroundColor: '#FFFFFF',
                px: '6px',
                zIndex: 99,
                borderRadius: '6px',
              }}
            >
              Banner Image
            </Typography>
          </Box>
        </Box>
      );
    },
    [getRootProps, getInputProps, isDragActive]
  );
  const getSocialMediaPrefix = (socialKey) => {
    switch (socialKey) {
      case 'linkedIn':
        return 'https://www.linkedin.com/in/';
      case 'youtube':
        return 'https://www.youtube.com/@';
      case 'tiktok':
        return 'https://www.tiktok.com/@';
      case 'instagram':
        return 'https://www.instagram.com/';
      default:
        return '';
    }
  };

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      gap={3}
    >
      <Box width='100%'>
        {avatarInput(values, touched, setTouched, setFieldValue)}
        {touched?.profilePhoto && Boolean(errors?.profilePhoto) && (
          <Typography
            variant='body1'
            color={'error'}
            fontSize={'0.75rem'}
            mt={1}
          >
            {errors?.profilePhoto as string}
          </Typography>
        )}
      </Box>

      {formMode === 'update' && (
        <Box width='100%'>
          {bannerInput(values)}
          {touched?.bannerPhoto && Boolean(errors?.bannerPhoto) && (
            <Typography
              variant='body1'
              color={'error'}
              fontSize={'0.75rem'}
              mt={1}
            >
              {errors?.bannerPhoto as string}
            </Typography>
          )}
        </Box>
      )}
      <TextField
        disabled={formSubmitting}
        autoComplete='given-name'
        name='fullName'
        fullWidth
        id='text-input-fullName'
        label='Full Name'
        value={values.fullName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.fullName && Boolean(errors.fullName)}
        helperText={touched.fullName && errors.fullName}
      />
      <TextField
        fullWidth
        id='text-input-userName'
        label='User Name'
        name='userName'
        disabled
        value={values.userName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.userName && Boolean(errors.userName)}
        helperText={touched.userName && errors.userName}
      />
      <TextField
        fullWidth
        id='text-input-email'
        label='Email Address'
        name='email'
        autoComplete='email'
        value={values.email}
        disabled
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && Boolean(errors.email)}
        helperText={
          (touched.email && errors.email ? errors.email : '') as string
        }
      />
      {auth?.currentUser?.emailVerified !== undefined ? (
        auth.currentUser.emailVerified ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
            <span>Email verified</span>
          </div>
        ) : (
          <div>
            <span>
              <a
                id='href-resend-verification-link'
                href='#'
                onClick={handleVerifyEmail}
                style={{
                  color: 'blue',
                  textDecoration: 'underline',
                }}
              >
                Click here
              </a>{' '}
              to resend the email with a verification link
            </span>
          </div>
        )
      ) : null}
      <FormControl fullWidth>
        <InputLabel
          id='age-select'
          // sx={{
          //   '&.MuiFormLabel-root': {
          //     top: '-6px',
          //   },
          //   '&.MuiInputLabel-shrink': {
          //     top: 0,
          //   },
          // }}
          error={touched.age && Boolean(errors.age)}
        >
          Select Age
        </InputLabel>
        <Select
          fullWidth
          disabled={formSubmitting}
          name='age'
          id='select-input-age'
          label='Select Age'
          labelId='age-select'
          placeholder='Select Age'
          size='small'
          value={values.age}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.age && Boolean(errors.age)}
          // helperText={touched.age && errors.age}
        >
          {AGE.map((ageItem, index) => (
            <MenuItem value={ageItem.value} key={index}>
              {ageItem.label}
            </MenuItem>
          ))}
        </Select>
        {touched.age && !!errors.age && (
          <FormHelperText error>{errors.age}</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth>
        <InputLabel
          id='gender-input'
          // sx={{
          //   '&.MuiFormLabel-root': {
          //     top: '-6px',
          //   },
          //   '&.MuiInputLabel-shrink': {
          //     top: 0,
          //   },
          // }}
          error={touched.gender && Boolean(errors.gender)}
        >
          Gender
        </InputLabel>
        <Select
          fullWidth
          disabled={formSubmitting}
          name='gender'
          id='select-input-gender'
          label='Gender'
          labelId='gender-input'
          placeholder='Gender'
          size='small'
          value={values.gender}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.gender && Boolean(errors.gender)}
          // helperText={touched.gender && errors.gender}
        >
          {GENDER.map((ageItem, index) => (
            <MenuItem value={ageItem.value} key={index}>
              {ageItem.label}
            </MenuItem>
          ))}
        </Select>
        {touched.gender && !!errors.gender && (
          <FormHelperText error>{errors.gender}</FormHelperText>
        )}
      </FormControl>
      <TextField
        disabled={formSubmitting}
        fullWidth
        name='birthPlace'
        id='text-input-birthPlace'
        label='Birthplace - City, State, Country'
        type='text'
        inputMode='text'
        value={values.birthPlace}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.birthPlace && Boolean(errors.birthPlace)}
        helperText={touched.birthPlace && errors.birthPlace}
      />
      <TextField
        disabled={formSubmitting}
        fullWidth
        name='residencePlace'
        id='text-input-residencePlace'
        label='Current Residence - City, State, Country'
        type='text'
        inputMode='text'
        value={values.residencePlace}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.residencePlace && Boolean(errors.residencePlace)}
        helperText={touched.residencePlace && errors.residencePlace}
      />
      <TextField
        disabled={formSubmitting}
        fullWidth
        name='occupation'
        id='text-input-occupation'
        label='Occupation'
        type='text'
        inputMode='text'
        value={values.occupation}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.occupation && Boolean(errors.occupation)}
        helperText={touched.occupation && errors.occupation}
      />
      <TextField
        disabled={formSubmitting}
        multiline
        rows={6}
        fullWidth
        id='text-input-shortBio'
        label='Add a short bio for your AI Persona'
        name='shortBio'
        value={values.shortBio}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.shortBio && Boolean(errors.shortBio)}
        helperText={touched.shortBio && errors.shortBio}
        sx={{
          '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline':
            {
              padding: 0,
            },
        }}
      />
      <Box display='flex' flexDirection='column' gap={1} width='100%'>
        <Typography
          variant='subtitle2'
          fontSize='1.1rem'
          color='#000'
          fontWeight={600}
        >
          Share Social Accounts
        </Typography>
        <Typography variant='subtitle2' fontSize='0.8rem' marginBottom='10px'>
          Allow others to follow you on different channels.
        </Typography>
      <Box display='flex' flexDirection='column' gap={3} width='100%'>
          {Object.entries({
            linkedIn: 'LinkedIn',
            youtube: 'YouTube',
            tiktok: 'TikTok',
            instagram: 'Instagram',
          }).map(([socialKey, socialLabel]) => (
            <TextField
              key={socialKey}
              disabled={formSubmitting}
              fullWidth
              id={`text-input-${socialKey}`}
              label={socialLabel}
              name={`${socialKey}URL`}
              value={values[`${socialKey}URL`]}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="username"
              InputProps={{
                startAdornment: (
                      <InputAdornment position='start'>
                        {getSocialMediaPrefix(socialKey)}
                      </InputAdornment>
                    ),
              }}

            />
          ))}
        </Box>
        </Box>
    </Box>
  );
}
