import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import SignInLanding from '../signinLanding';
import { Height } from '@mui/icons-material';

interface SignInSignUpModalType {
  handleSignInModal: boolean,
  setHandleSignInModal: React.Dispatch<React.SetStateAction<boolean>>,
  showVerifyEmail?: boolean,
  setShowVerifyEmail?: React.Dispatch<React.SetStateAction<boolean>>,
}

const SignInSignUpModal = (props: SignInSignUpModalType) => {
  const { handleSignInModal, setHandleSignInModal, showVerifyEmail=false, setShowVerifyEmail } = props;

  return (
    <Dialog
      open={Boolean(handleSignInModal)}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '750px',
            borderRadius: '0px'
          },
        },
      }}

      PaperProps={{
        style:{
            maxHeight: '100%',
            height: '95%',
            width: '93%',
            margin: 0,
        }
      }}
      container={() => document.getElementById('chat-container')}
    >
      <DialogContent sx={{overflowY:'hidden'}}>
        <SignInLanding isDialog setHandleSignInModal={setHandleSignInModal} isWidget showVerifyEmail={showVerifyEmail} setShowVerifyEmail={setShowVerifyEmail}/>
      </DialogContent>
    </Dialog>
  );
};

export default SignInSignUpModal;
