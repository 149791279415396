import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { FormMode } from './signup.types';
import { getIntroMessage } from '../../utils/api';
import { List, ListItem } from '@mui/material';
import { useState } from 'react';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';

interface CreatorOnBoardingStepThreeProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  setTouched: any;
  isValid: any;
  dirty: any;
  formSubmitting: boolean;
  formMode: FormMode;
  userData: any;
}

export default function CreatorOnBoardingStepThree(
  props: CreatorOnBoardingStepThreeProps
) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    formSubmitting,
    formMode,
    userData,
  } = props;

  const enableIntroMessages = process.env.REACT_APP_USE_INTRO_MESSAGES === 'true';
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const eventTracker = useAnalyticsEventTracker();

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={4}
      width='100%'
      mt={formMode === 'update' ? 4 : 0}
    >
      {enableIntroMessages &&
        <Box  display='flex' flexDirection='column' gap={1} width='100%'>
          <Typography
            variant='subtitle2'
            fontSize='1.1rem'
            color='#000'
            fontWeight={600}
          >
            AI Persona Introduction
          </Typography>
          <Typography variant='subtitle2' fontSize='0.8rem'>
            This introduction is generated from the information you provide (links, websites, documents, etc.). It will display when a user starts a chat or talk with your AI persona. You can edit it or generate a new introduction at any time.
          </Typography>
          <Typography variant='subtitle2' fontSize='0.8rem' color='#000' fontWeight={600}>
            Requirements:
          </Typography>
          <List sx={{
            listStyleType: 'disc',
            display: 'flex',
            flexDirection: 'column',
            gap: 0,
            width: '100%',
            pt: 0,
            pl: '25px',
          }}>
            <ListItem sx={{display: 'list-item', p: 0}}>
              <Typography variant='subtitle2' fontSize='0.8rem'>
                Minimum of 100 characters
              </Typography>
            </ListItem>
            <ListItem sx={{display: 'list-item', p: 0}}>
              <Typography variant='subtitle2' fontSize='0.8rem'>
                Minimum of 3 sentences
              </Typography>
            </ListItem>
          </List>

          <TextField
            disabled={formSubmitting}
            multiline
            rows={6}
            fullWidth
            id='text-input-introMessage'
            label='Add an introduction for your AI Persona'
            name='introMessage'
            value={values.introMessage}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.introMessage && Boolean(errors.introMessage)}
            helperText={touched.introMessage && errors.introMessage}
            InputLabelProps={{ shrink: !!values?.introMessage || values?.introMessage?.focused}}
            sx={{
              '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline':
                {
                  padding: 0,
                },
            }}
          />
          <Button
            id='button-generate-intro'
            variant='outlined'
            fullWidth
            onClick={async () => {
              setFieldValue('introMessage', 'Generating new introduction...')
              if (!isGenerating){
                setIsGenerating(true)
                getIntroMessage(values.documentId, true)
                  .then((resp) => {
                      if (resp.status === 200){
                        eventTracker('generate-intro success')
                        return resp.json();
                      } else {
                        eventTracker('generate-intro failed')
                        setIsGenerating(false)
                      }
                    }
                  ).then((message) => {
                    setFieldValue('introMessage', message?.response || "")
                    setIsGenerating(false)
                  }).catch((err) => {
                    eventTracker('generate-intro failed')
                    setIsGenerating(false)
                  })
              }
            }}
            startIcon={<AutoAwesomeIcon />}
          >
            Generate Introduction
          </Button>
        </Box>
      }
      {userData.lastIndexedOn &&
        <Typography variant='subtitle2' fontSize='0.8rem' sx={{fontStyle: 'italic'}}>
          Persona last updated on {userData.lastIndexedOn}
        </Typography>
      }
    </Box>
  );
}
