
import { Link } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ChatIcon from '@mui/icons-material/Chat';
import UserProfileData from '../userProfile/userProfileData';
import React, { useState } from 'react';
import { grey } from '@mui/material/colors';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import { Grid } from '@mui/material';
import ChatButtonIcon from '../../svg/ChatButtonIcon';
import Avatar from '@mui/material/Avatar';

interface FanCreatorProfileItemType {
  profileData: {
    fullName: string;
    profilePicUrl: string;
    userName: string;
    shortBio: string;
    fanCount: number;
    messageCount: number;
    voiceId: string;
  };
}


const formatCount = (num) => {
  // Check if num is undefined or null
  if (num == null) return '0'; // Returns '0' if num is undefined or null

  if (num >= 1000) return (num / 1000).toFixed(1) + 'k';
  return num.toString();
};

export default function FanCreatorProfileItem(props: FanCreatorProfileItemType) {
  const {
    fullName,
    profilePicUrl,
    userName,
    shortBio,
    fanCount,
    messageCount,
    voiceId,
  } = props.profileData;

  // Setup the chat URL
  const chatUrl = `/${userName}/chat`;
  const profileUrl = `/${userName}`;

  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
      my: 2,
      alignItems: 'flex-start',
      flexDirection: 'column',
      height: '150px',
      textAlign: 'left',
      position: 'relative', // Set relative positioning for the parent container
      margin: '0px',
    }}>
      {/* Profile Section */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mr: 2,
        paddingTop: '10px',
        width: '110px',
        position: 'absolute',
      }}>
        {/* Profile Picture */}
        <Link id={`href-profile-${userName}`} to={profileUrl}>
        <Avatar
          src={profilePicUrl}

          alt={fullName}
          sx={{
            mb: 1,
            width: 80,
            height: 80,
            fontSize: '2.5rem'
          }}
        >{fullName ? fullName[0] : ''}</Avatar>
        {
          voiceId ?
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              width: 23,
              height: 23,
              borderRadius: '50%',
              top: 68,
              left: 72,
              backgroundColor: 'white',
              borderColor: grey[500] + '!important',
              border: 'solid 1px'
            }}
          >
            <GraphicEqIcon
              sx={{
                width: 15,
                height: 15,
                color: `${grey[500]}`
              }}
            />
          </Box> : <></>
        }
        </Link>
        {/* Fans and Messages Count in the same line */}
        <Box sx={{ display: 'flex', alignItems: 'center', color: 'grey', marginTop: '10px' }}>
          <PersonIcon sx={{ marginRight: '4px', fontSize: '15px'}} />
          <Typography variant="body2">{formatCount(fanCount)}</Typography>
          <ChatIcon sx={{ marginLeft: '12px', marginRight: '4px',fontSize: '15px' }} />
          <Typography variant="body2">{formatCount(messageCount)}</Typography>
        </Box>
      </Box>

      {/* Text Section */}
      <Box sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Use space-between to keep the header at the top
        marginTop: '6px',
        paddingLeft: '120px',
        paddingRight: '5px'
      }}>
        {/* Text Content */}
        <Box>
          <Typography component='h6' variant='h6' fontWeight={600}  sx={{
            fontSize: '18px', // Change the font family
            color: 'black',
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            height: '1.5em',
            wordBreak: 'break-all'
          }}>

            {fullName}
          </Typography>
          <Typography component='p' variant='body2' color='text.secondary'>
            {`@${userName}`}
          </Typography>
          <Typography component='p' variant='body2' sx={{
            fontSize: '14px', // Change the font family
            color: 'black',
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            height: '2.8em',
            wordBreak: 'break-all'
          }}>
            {shortBio}
          </Typography>
        </Box>
      </Box>

      {/* Buttons - Positioned absolutely within the parent container */}
      <Box sx={{
        flexDirection: 'row',
        position: 'absolute', // Position absolutely within the relative parent
        bottom: 8, // Stick to the bottom
        right: 0, // Align to the right
        display: 'flex',
        left: '110px',
        paddingRight: '9px',
        paddingBottom:'3px'
      }}>
        <Link id={`href-chat-${userName}`} to={chatUrl} style={{ textDecoration: 'none', flex: 1, marginLeft: '10px' }}>
          <Button id={`button-chat-${userName}`} variant="contained" sx={{ height: '32px !important', padding: '6px 12px !important', borderRadius: '6px', backgroundColor: window.location.href.startsWith('https://stanford.soopra.ai') ? '#8C1515 !important' : 'primary.main' }} fullWidth>
            <ChatButtonIcon style={{ marginRight: 10 }} /> Chat
          </Button>
        </Link>
        {
          voiceId ?
          <Link id={`href-voice-${userName}`} to={chatUrl} state={{ useVoiceChat: true }} style={{ textDecoration: 'none', flex: 1, marginLeft: '8px', marginRight: '5px' }}>
          <Button id={`button-voice-${userName}`} variant="contained" sx={{ height: '32px!important', padding: '6px 12px !important', borderRadius: '6px', backgroundColor: 'white !important', border: window.location.href.startsWith('https://stanford.soopra.ai') ? '1px solid #8C1515' : '1px solid #009CDB', color: window.location.href.startsWith('https://stanford.soopra.ai') ? '#8C1515 !important' : '#009CDB !important' }} fullWidth>
            <GraphicEqIcon sx={{ marginRight: 1 }} /> Talk
          </Button>
          </Link> :
            <Button
              id={`button-voice-disabled-${userName}`}
              variant="contained"
              disabled
              sx={{
                backgroundColor: 'white !important',
                border: '1px solid #009CDB',
                height: '32px!important',
                padding: '6px 0px !important',
                borderRadius:'6px',
                flex: 1,
                marginLeft: '8px' ,
                marginRight: '5px',
                color: grey[300] + '!important',
                borderColor: grey[300] + '!important',
              }}
              fullWidth
            >
              <GraphicEqIcon sx={{ marginRight: 1 }} />
              Talk
            </Button>
        }
      </Box>
      </Box>
  );
}
