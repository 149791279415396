const TermsAndConditions = [
  {
    title: '',
    content:
      'Welcome to Soopra. By using our website, you agree to the following Terms and Conditions and Privacy Policy:',
  },
  {
    title: 'Terms and Conditions',
    content:
      'Acceptance of Terms: By accessing and using the Soopra.ai web app, you agree to comply with these Terms and Conditions.\n\n Intellectual Property: All content and materials on the soopra.ai web app, including but not limited to text, graphics, logos, images, and software, are the intellectual property of soopra.ai and are protected by applicable copyright and trademark laws.\n\n Use of web app: You may use the soopra.ai web app for personal, non-commercial purposes only. You must not modify, distribute, reproduce, or sell any content from the web app without prior written permission from soopra.ai.\n\n User Responsibilities: When using the soopra.ai web app, you agree not to engage in any unlawful or unauthorized activities, including but not limited to hacking, spreading malware, or interfering with the web app functionality.\n\n Privacy: Your privacy is important to us. Please refer to our Privacy Policy for detailed information on how we collect, use, and protect your personal data.\n\n Third-Party Links: The soopra.ai web app may contain links to third-party web apps. We are not responsible for the content, policies, or practices of these external sites and encourage you to review their respective terms and conditions.\n\n Disclaimer of Warranties: The soopra.ai web app is provided on an "as is" and "as available" basis. We make no warranties or representations regarding the accuracy, completeness, or reliability of the web app content or its suitability for any purpose.\n\n Limitation of Liability: In no event shall soopra.ai be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the web app.\n\n Governing Law: These Terms and Conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which soopra.ai operates.',
  },
  {
    title: 'Privacy Policy',
    content:
      'Data Collection: We may collect personal information, such as your name and email address when you voluntarily provide it to us through forms on the soopra.ai web app. We also collect non-personal information such as IP addresses and browsing behavior for analytics purposes.\n\n Use of Information: We use the information collected to provide you with relevant content, improve our services, communicate with you, and personalize your experience on the soopra.ai web app. We do not sell or share your personal information with third parties without your consent.\n\n Cookies: The soopra.ai web app may use cookies and similar technologies to enhance your browsing experience and collect information about how you use the site. You can manage your cookie preferences through your browser settings.\n\n Data Security: We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no data transmission over the internet or storage system can be guaranteed to be 100% secure.\n\n Third-Party Services: We may utilize third-party services, such as analytics providers or marketing platforms, to assist us in delivering and improving our services. These third parties may have access to your personal information as necessary to perform their functions but are obligated to maintain its confidentiality.\n\n Changes to the Privacy Policy: We reserve the right to update or modify our Privacy Policy at any time. Any changes will be posted on this page, and your continued use of the soopra.ai web app after the modifications indicate your acceptance of the updated Privacy Policy.',
  },
  {
    title: '',
    content:
      'Please review the Terms and Conditions and Privacy Policy carefully before using the soopra.ai web app. If you do not agree with any of these terms, please refrain from accessing or using the web app.',
  },
];

export default TermsAndConditions;
