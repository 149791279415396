import React, { useRef, useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { fetchResultsByStudentAndCourse, getCourseInfo } from "../../utils/api";
import { useAuth } from "../../hooks/useAuth";
import { Box, Typography, Button } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const AssignmentReview: React.FC = () => {
  const { courseId, studentId } = useParams();
  const [ courseData, setCourseData ] = useState(null);
  const [ results, setResults ] = useState(null);
  const location = useLocation();
  const isLoading = useRef<boolean>(false);
  const { authUser } = useAuth();
  const [ currentModule, setCurrentModule ] = useState<number>(1);
  const [ assignmentQuestions, setAssignmentQuestions ] = useState([]);
  const [ selectedAnswers, setSelectedAnswers ] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadCourseData();
  }, [])

  useEffect(() => {
    if (courseData && authUser) {
      // only allow authUser === instructor to stay
      if (authUser?.uid !== courseData?.creator_id){
        navigate(`/courses/${courseId}`)
      } else {
        loadResults();
      }
    }
  }, [ courseData, authUser ])

  useEffect(() => {
    if (results?.module && results.module[currentModule] && courseData) {
      setAssignmentQuestions(courseData.modules[currentModule-1].questions)
      setSelectedAnswers(results.module[currentModule].questions.map(ans => ans.selectedOption))
    } else {
      setAssignmentQuestions([]);
      setSelectedAnswers([]);
    }
  }, [ courseData, results, currentModule ])

  let loadCourseData = () => {
    if (location?.state?.course) {
      setCourseData(location.state.course)
    } else {
      isLoading.current = true;
      getCourseInfo(courseId)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error(`Course not found`);
          }
        })
        .then(data => {
          isLoading.current = false;
          setCourseData(data);
        })
        .catch(err => {
          // To-do: error handling on course fetch
        })
    }
  }

  let loadResults = () => {
    if (location?.state?.results) {
      setResults(location.state.results)
    } else {
      isLoading.current = true;
      fetchResultsByStudentAndCourse(studentId, courseId)
        .then(results => {
          isLoading.current = false;
          if (results.length) {
            setResults(results[0]);
          } else {
            throw new Error(`Results not found`);
          }
        })
        .catch(err => {
          // To-do: error handling on results fetch
        })
    }
  }

  const AssignmentAnswers = () => {
    return (
      results?.module && results.module[currentModule] ?
      <Box
        sx={{mt: 4, ml: 2, mr: 2}}
      >
        {assignmentQuestions.map((questionObj: any, index: number) => (
          <Box key={`question-${currentModule}-${index}`} mb={4} display="flex" alignItems="flex-start">
            <Box>
              <Typography variant="body1" sx={{ fontSize: '1rem',  color: 'black' }}>
                {`${index + 1}. ${questionObj.question}`}
              </Typography>
              <Box mt={2}>
                {questionObj.options.map((optionObj, optionIndex) => {
                  const isCorrectOption = optionObj.isCorrect;
                  const isSelectedOption = selectedAnswers[index] === optionObj.option;
                  return (
                    <Button
                      key={optionIndex}
                      variant="outlined"
                      sx={{
                        display: 'block',
                        textAlign: 'left',
                        mb: 1,
                        padding: '6px 12px', // Adjust the padding to make the button smaller
                        fontSize: '0.875rem', // Smaller font size for the option text
                        marginLeft:'20px',
                        borderRadius: '5px',
                        maxHeight: '40px',
                        fontWeight: 'normal !important',
                        backgroundColor: isCorrectOption ? '#3DC252' : (isSelectedOption ? '#FF6C6C' : 'inherit'),
                        color: isCorrectOption ? 'white !important' : (isSelectedOption ? 'white !important' : 'inherit'),
                        borderColor: isSelectedOption ? (isCorrectOption ? '#3DC252' : '#FF6C6C') : 'inherit',
                        '&:hover': {
                          backgroundColor: isCorrectOption ? '#3DC252' : (isSelectedOption ? '#FF6C6C' : 'inherit'),
                        },
                      }}
                    >
                      {optionObj.option}
                    </Button>
                  );
                })}
                </Box>
              </Box>
            </Box>
        ))}
      </Box> :
      <Box
        sx={{mt: 4, display: 'flex', justifyContent: 'center'}}
      >
        <Typography>
          Assignment not completed
        </Typography>
      </Box>
    )
  };

  return (
    <Box
      sx={{
        mt: 1,
        mb: 2,
      }}
    >
      <Typography
        fontSize={'1rem'}
        fontWeight={700}
        align='left'
        color={'black'}
        sx={{pl: 2}}
      >
        { results?.studentDetails?.fullName }
      </Typography>
      <Typography
        fontSize={'0.9rem'}
        fontWeight={400}
        align='left'
        color={'gray'}
        sx={{
          mb: 1,
          pl: 2,
        }}
      >
        { courseData?.name }
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 1,
          pt: 1,
          borderTop: 'solid 1px #bbb',
          borderBottom: 'solid 1px #bbb',
        }}
      >
        <Button
          size="small"
          disabled={currentModule === 1}
          onClick={(event) => {
            event.preventDefault();
            if (currentModule > 1) {
              setCurrentModule(currentModule-1)
            }
          }}
          sx={{p: 0}}
        >
          <NavigateBeforeIcon />
        </Button>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
          }}
        >
          <Typography
            fontSize={'0.9rem'}
            fontWeight={700}
            align='left'
            color={'black'}
          >
            { `Assignment: Module ${currentModule}/${courseData?.module_count}:` }&nbsp;
          </Typography>
          <Typography
            fontSize={'0.9rem'}
            fontWeight={400}
            align='left'
            color={'black'}
          >
            { results?.module && results.module[currentModule] ?
              `Score: ${results.module[currentModule].score}/${courseData?.modules[currentModule-1].questions?.length}` :
              `Score: 0/${courseData?.modules[currentModule-1].questions?.length}`
            }
          </Typography>
        </Box>
        <Button
          size="small"
          disabled={currentModule === courseData?.module_count}
          onClick={(event) => {
            event.preventDefault();
            if (currentModule < courseData?.module_count) {
              setCurrentModule(currentModule+1)
            }
          }}
          sx={{p: 0}}
        >
          <NavigateNextIcon />
        </Button>
      </Box>

      <AssignmentAnswers/>
    </Box>
  )
}

export default AssignmentReview;
