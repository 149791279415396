import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Grid } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { deleteCourse, searchUnsplash, uploadCourseBanner } from "../../utils/api";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { useAuth } from "../../hooks/useAuth";
import { handleDeleteCourse } from "../../utils/helper";

const CourseBanner = () => {
  let [courseData, setCourseData] = useState<any>(null);
  let [searchValue, setSearchValue] = useState<string>("")
  let [images, setImages] = useState([]);
  let [selectedImage, setSelectedImage] = useState(null);
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [isSaving, setIsSaving] = useState<boolean>(false);
  let page = useRef<number>(1);
  const bottom = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const shouldDeleteCourseRef = useRef<boolean>(true);
  const initialLoad = useRef<boolean>(true);
  const { authUser } = useAuth();
  const { courseId } = useParams();

  useEffect(() => {
    if (location?.state?.courseId && location?.state?.courseData) {
      setCourseData(location.state.courseData)
      setSearchValue(location.state.courseData.name || "")
    } else {
      // navigate(-1);
    }
  }, [])

  useEffect(() => {
    if (searchValue.length && initialLoad.current){
      initialLoad.current = false;
      handleSearch();
    }
  }, [searchValue])

  useEffect(() => {
    try {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (searchValue?.length) {
            handleSearch();
          }
        }
      });
      observer.observe(bottom.current);

      return () => {
        observer.disconnect();
      };
    }
    catch (err) {
      // To-do: Error handling when observer fails
    }
  }, [images])

  useEffect(() => {
    return () => {
      if (shouldDeleteCourseRef.current) {
        handleDeleteCourse(courseData, authUser)
      }
    };
  }, [courseData])

  const handleSearch = () => {
    setIsLoading(true);
    searchUnsplash(searchValue, page.current)
      .then(data => {
        setImages(images.concat(data.results));
        page.current += 1;
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error in handleSearch', error)
      })
  };

  const handleNext = async () => {
    let unsplash_url = selectedImage?.urls?.small;
    if (courseId) {
      try {
        setIsSaving(true)
        uploadCourseBanner(courseId, unsplash_url)
          .then(() => {
            const updatedCourseInfo = {
              ...courseData,
              banner_image: unsplash_url,
              creator_avatar: authUser.profilePicUrl,
            };
            shouldDeleteCourseRef.current = false;
            navigate(`/CoursePreview/${courseId}`, {state: {courseId, courseData: updatedCourseInfo}})
          })
          .catch(err => {
            throw err
          })
          .finally(() => {
            setIsSaving(false)
          })
      } catch (error) {
        console.log(error)
      }
    }
  };

  return (
    <Box sx={{pl: {xs: 2, md: 0}, pr: {xs: 2, md: 0}}}>
      <div style={{ width: '100%' }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: '#EFEFEF', py: 1, width: '100%'}}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Create New Course
          </Typography>
        </Box>
      </div>
      <Box pt={2} pb={2}>
        <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
          Course Image
        </Typography>
        <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} >
          Choose an image to appear in your course listing. This is required to create a course.
        </Typography>
      </Box>
      <Grid container spacing={{xs: 0, md: 2}} sx={{alignItems: 'center', pb: 2}}>
        <Grid item xs={12} md={9}>
          <TextField
            type='text'
            margin='normal'
            fullWidth
            id='text-input-search'
            label='Search images'
            name='search'
            autoComplete='off'
            className='course-banner-search'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              '&.course-banner-search input': {
                borderWidth: 0,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={isLoading}
            fullWidth
            onClick={() => {
              setImages([]);
              page.current = 1;
              handleSearch();
            }}
            sx={{
              '&.Mui-disabled': {
                backgroundColor: '#8ad5f8 !important',
              },
              height: {xs: 'auto', md: '51px'},
              mt: {xs: 0, md: 1}
            }}
            startIcon={<SearchIcon/>}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={{xs: 0}}>
        {images.map((image) => {
          return (
            <Grid
              item
              key={image?.id}
              xs={6}
              md={4}
            >
              <Button
                onClick={() => {
                  setSelectedImage(image)
                }}
                sx={{
                  height: 'fit-content !important',
                  border: image?.id === selectedImage?.id ? '2px solid #009CDB' : '2px solid transparent',
                  borderRadius: 0,
                }}
              >
                <img
                  src={image?.urls?.small}
                  width='100%'
                  style={{
                    objectFit: 'cover',
                    aspectRatio: 1.78,
                  }}
                  loading="lazy"
                />
              </Button>
            </Grid>
          )
        })}
      </Grid>
      {
        isLoading &&
          <Box
            sx={{display: 'flex', justifyContent: 'center', width: '100%'}}
          >
            <CircularProgress size={22}/>
          </Box>
      }
      <div className="custom-bottom" ref={bottom}/>
      {<Box
        sx={{
          position: 'sticky',
          bottom: '0',
          left: '0',
          right: '0',
          height: selectedImage ? '60px' : '0px',
          display: 'flex',
          backgroundColor: 'white',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          transition: 'height 0.5s'
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            // color="primary"
            size="large"
            onClick={() => {
              setSelectedImage(null);
            }}
            disabled={isSaving}
            sx={{
              mr: 2,
              '&.Mui-disabled': {
                backgroundColor: '#8ad5f8 !important',
              },
              display: isSaving ? 'none' : 'flex'
            }}
            startIcon={<ChangeCircleIcon/>}
          >
            Choose again
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleNext}
            startIcon={isSaving ? <CircularProgress size={22}/> : <></>}
            endIcon={isSaving ? <></> : <ArrowForwardIcon/>}
            sx={{
              mr: 1,
              '&.Mui-disabled': {
                backgroundColor: '#8ad5f8 !important',
              },
            }}
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Next'}
          </Button>
        </Box>
      </Box> }
    </Box>
  )
}

export default CourseBanner;
