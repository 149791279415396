import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Palette {
    twitterIcon: Palette['primary'];
    linkedinIcon: Palette['primary'];
    instagramIcon: Palette['primary'];
    youtubeIcon: Palette['primary'];
    tiktokIcon: Palette['primary'];
    mainBlue: Palette['primary'];
    lightBlue: Palette['primary'];
    darkBlue: Palette['primary'];
    themeBlue: Palette['primary'];
    themeGrey: Palette['primary'];
  }

  interface PaletteOptions {
    twitterIcon?: PaletteOptions['primary'];
    linkedinIcon?: PaletteOptions['primary'];
    instagramIcon?: PaletteOptions['primary'];
    youtubeIcon?: PaletteOptions['primary'];
    tiktokIcon?: PaletteOptions['primary'];
    mainBlue?: PaletteOptions['primary'];
    lightBlue?: PaletteOptions['primary'];
    darkBlue?: PaletteOptions['primary'];
    themeBlue?: PaletteOptions['primary'];
    themeGrey?: PaletteOptions['primary'];
  }

  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    twitterIcon: true;
    linkedinIcon: true;
    instagramIcon: true;
    youtubeIcon: true;
    tiktokIcon: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    mainBlue: true;
    lightBlue: true;
    darkBlue: true;
  }
}

declare module '@mui/material/ToggleButton' {
  interface ToggleButtonPropsColorOverrides {
    mainBlue: true;
    lightBlue: true;
    darkBlue: true;
  }
}

declare module '@mui/material/ToggleButtonGroup' {
  interface ToggleButtonGroupPropsColorOverrides {
    mainBlue: true;
    lightBlue: true;
    darkBlue: true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    mainBlue: true;
    lightBlue: true;
    darkBlue: true;
  }
}

declare module '@mui/material/Slider' {
  interface SliderPropsColorOverrides {
    mainBlue: true;
    lightBlue: true;
    darkBlue: true;
  }
}

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    mainBlue: true;
    lightBlue: true;
    darkBlue: true;
  }
}

const THEME_PALETTE = {
  primary: {
    main: '#2b1169',
  },
  secondary: {
    main: '#c55996',
  },
  error: {
    main: red.A400,
  },
  twitterIcon: {
    main: '#1DA1F2',
  },
  linkedinIcon: {
    main: '#0077B5',
  },
  instagramIcon: {
    main: '#d62976',
  },
  youtubeIcon: {
    main: '#FF0000',
  },
  tiktokIcon: {
    main: '#ff0050',
  },
  mainBlue: {
    main: '#3EACEF',
    light: '#8ad5f8',
    dark: '#3a9de0',
    contrastText: '#FFFFFF',
  },
  lightBlue: {
    main: '#8ad5f8',
  },
  darkBlue: {
    main: '#3a9de0',
  },
  action: {
    disabledBackground: '#D9D9D9',
    disabled: 'white',
  },
  themeBlue: {
    main: '#009CDB',
    contrastText: '#FFF',
  },
  themeGrey: {
    contrastText: '#FFF',
    main: '#E9E9E9',
  },
};

// A custom theme for this app
let theme = createTheme({
  palette: THEME_PALETTE,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none',
          boxShadow: 'none',
          '&.MuiButton-sizeMedium': {
            height: '50px',
          },
          '&.MuiButton-contained': {
            fontSize: '16px',
            fontWeight: 600,
            backgroundColor: THEME_PALETTE.themeBlue.main,
          },
          '&.MuiButton-sizeSmall': {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: 500,
            height: '36px',
          },
          '&.MuiButton-outlined': {
            borderColor: '#312D2D',
            color: '#50555C',
          },
          '&.MuiButton-contained.Mui-disabled': {
            color: THEME_PALETTE.themeGrey.contrastText,
            backgroundColor: THEME_PALETTE.themeGrey.main,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'medium',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: '10px',
          borderColor: '#312D2D',
        },
        input: {
          padding: '14px',
        },
      },
    },
    MuiCircularProgress:{
      styleOverrides: {
        circle: {
          color: THEME_PALETTE.themeBlue.main
        }
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1900,
    },
  },
});

export default theme;
