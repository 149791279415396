import { Box, Container } from '@mui/system';
import TermsAndConditions from './terms';

const Terms = () => {
  return (
    <Box>
      <Container>
        <div style={{ whiteSpace: 'pre-line' }}>
          {TermsAndConditions.map((section, index) => (
            <div key={index}>
              <h2>{section.title}</h2>
              <p>{section.content}</p>
            </div>
          ))}
        </div>
      </Container>
    </Box>
  );
};

export default Terms;
