import React, { useRef, useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom";
import { fetchResultsByCourse, getCourseInfo } from "../../utils/api";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Typography, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAuth } from "../../hooks/useAuth";
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import CloseIcon from '../../svg/CloseIcon';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

const CourseReview: React.FC = () => {
  const { courseId } = useParams();
  const [ results, setResults ] = useState(null);
  const { authUser } = useAuth();
  const [ courseData, setCourseData ] = useState(null);
  const [ modalData, setModalData ] = useState(null);
  const [ mobileModalVisible, setMobileModalVisible ] = useState(false);
  const [ refresh, setRefresh ] = useState<boolean>(false);
  const moduleCount = useRef<number>(4);
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ isLoadingResults, setIsLoadingResults ] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    if (courseId){
      getCourseInfo(courseId)
        .then((res) => {
          if (res.ok){
            return res.json();
          } else {
            throw new Error(`Course not found`);
          }
        })
        .then((data) => {
          setCourseData(data);
          moduleCount.current = data?.module_count || 4;
          setIsLoading(false);
        })
        .catch(err => {
          // To-do: error handling
        })
    }
  }, [courseId])

  useEffect(() => {
    if (authUser && courseData){
      // only allow authUser === instructor to stay
      if (authUser?.uid !== courseData?.creator_id){
        navigate(`/courses/${courseId}`)
      } else {
        setIsLoadingResults(true)
        fetchResultsByCourse(courseId)
          .then((studentProgressData) => {
            // console.log(studentProgressData)
            setResults(studentProgressData)
            setIsLoadingResults(false)
          })
      }
    }
  }, [authUser, courseData])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      paddingTop: '10px',
      paddingBottom: '10px',
      fontSize: 16,
      fontWeight: 600,
      backgroundColor: '#777',
      color: 'white',
      borderLeft: '1px solid #bbb',
      ':first-of-type': {
        borderLeft: 'none'
      }
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      border: 'none',
      borderLeft: '1px solid #bbb',
      borderRight: '1px solid #bbb',
      ':first-of-type': {
        borderLeft: 'none'
      },
      ':last-of-type': {
        borderRight: 'none'
      }
    },
  }));

  const RowModuleCompletion = (row: any, i: number) => {
    let isCompleted = false;
    if (row?.detailedProgress){
      if (row?.detailedProgress[`module_${i+1}`]){
        if (row?.detailedProgress[`module_${i+1}`]['completed']){
          isCompleted = true;
        }
      }
    }

    return (
      <Box
        sx={{
          minWidth: '30px',
          minHeight: '30px',
        }}
      >
        {isCompleted ?
          <CheckIcon /> :
          ' '}
      </Box>
    )
  };

  const handleMobileMoreButton = (event, row) => {
    event.preventDefault();
    setModalData(row);
    setMobileModalVisible(true);
  }

  const handleReviewAssignments = (event, row) => {
    event.preventDefault();
    if (row?.courseId && row.studentId) {
      navigate(`/results/${row.courseId}/${row?.studentId}`, {state:
        {
          course: courseData,
          results: row,
        }
      })
    }
  };

  const handleSendCertificate = (event, row) => {
    event.preventDefault();
    // To-do: Send certificate
    console.log('send certificate')
    // console.log(row)
  };

  const MobileModal = () => {
    return (
      modalData ?
      <Dialog
        open={mobileModalVisible}
        onClose={() => {
          setModalData(null);
          setMobileModalVisible(false);
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogContent>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <IconButton
                id='button-close-review-assignment-modal'
                onClick={() => {
                  setModalData(null);
                  setMobileModalVisible(false);
                }}
                color='primary'
                sx={{
                  alignSelf: 'flex-end',
                  minWidth: '26px',
                  minHeight: '26px'
                }}
              >
                <CloseIcon height='20px' width='20px' />
              </IconButton>
            </Box>
            <Typography
              fontSize={'1rem'}
              fontWeight={700}
              align='left'
              color={'black'}
            >
              { modalData?.studentDetails?.fullName }
            </Typography>
            <Typography
              fontSize={'0.9rem'}
              fontWeight={400}
              align='left'
              color={'gray'}
              sx={{
                mb: 2,
              }}
            >
              { courseData?.name }
            </Typography>

            <TableContainer>
              <Table size="small" aria-label="a dense table" className="course-review-table"
                sx={{width: '100%', border: 'solid 1px #bbb'}}
              >
                <TableHead>
                  <TableRow>
                    {[...Array(moduleCount.current)].map((e, i) =>
                      <StyledTableCell align="center" key={`module-${i+1}`} className="mobile-padding0">
                        <Box className="mobile-visible" sx={{display: 'none', pt: 1, pb: 1, minWidth: '30px', justifyContent: 'center'}}>
                          {`M${i+1}`}
                        </Box>
                      </StyledTableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={modalData?.docId}
                  >
                    {[...Array(moduleCount.current)].map((e, i) =>
                      <StyledTableCell align="center" key={`${modalData?.docId}-module-${i+1}`} >
                        {RowModuleCompletion(modalData, i)}
                      </StyledTableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button
                id={`button-review-assignments-${modalData.docId}`}
                variant="contained"
                sx={{
                  height: '32px !important',
                  padding: '6px 12px !important',
                  borderRadius:'6px',
                  width: '100%',
                  mt: 2,
                  mb: 2,
                  color: 'black',
                  backgroundColor: 'white !important',
                  border: '1px solid black'
                }}
                fullWidth
                onClick={(event) => handleReviewAssignments(event, modalData)}
              >
                Review Assignments
              </Button>
              <Button
                id={`button-send-certificate-${modalData.docId}`}
                variant="contained"
                sx={{
                  height: '32px !important',
                  padding: '6px 12px !important',
                  borderRadius:'6px',
                  width: '100%',
                  '&.Mui-disabled': {
                    color: 'white !important',
                  },
                }}
                fullWidth
                onClick={(event) => handleSendCertificate(event, modalData)}
                disabled={!modalData?.detailedProgress?.course_completed}
              >
                Send Certificate
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog> :
      <></>
    )
  }

  return (
    isLoading ?
    <Box
      display='flex'
      width='100%'
      padding='1.5rem'
      alignItems='center'
      justifyContent='center'
    >
      <CircularProgress size={30} />
    </Box> :
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Typography
        fontSize={'1rem'}
        fontWeight={700}
        align='left'
        color={'black'}
        sx={{
          m: 2
        }}
      >
        {courseData?.name}
      </Typography>
      <TableContainer>
        <Table size="small" aria-label="a dense table" className="course-review-table"
          sx={{width: '100%'}}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{whiteSpace: 'nowrap'}}>Student Name</StyledTableCell>
              {[...Array(moduleCount.current)].map((e, i) =>
                <StyledTableCell align="center" key={`module-${i+1}`} className="mobile-padding0">
                  <Box className="mobile-hidden" >
                    {`Module ${i+1}`}
                  </Box>
                  <Box className="mobile-visible" sx={{display: 'none', p: 0, minWidth: '30px', justifyContent: 'center'}}>
                    {`M${i+1}`}
                  </Box>
                </StyledTableCell>
              )}
              <StyledTableCell align="center"> </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results?.map((row) => {
              return (
                <TableRow
                  key={row.docId}
                  sx={{
                    '&:nth-of-type(even)': {
                      backgroundColor: '#eee',
                    },
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row?.studentDetails?.fullName}
                  </StyledTableCell>
                  {[...Array(moduleCount.current)].map((e, i) =>
                    <StyledTableCell align="center" key={`${row.docId}-module-${i+1}`} className="mobile-padding0">
                      {RowModuleCompletion(row, i)}
                    </StyledTableCell>
                  )}
                  <StyledTableCell component="th" align="center" scope="row" className="mobile-padding0">
                    <Box
                      className='mobile-hidden'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        id={`button-review-assignments-${row.docId}`}
                        variant="contained"
                        sx={{
                          height: '32px !important',
                          padding: '6px 12px !important',
                          borderRadius:'6px',
                          width: '200px',
                          mr: 4,
                          color: 'black',
                          backgroundColor: 'white !important',
                          border: '1px solid black'
                        }}
                        fullWidth
                        onClick={(event) => handleReviewAssignments(event, row)}
                      >
                        Review Assignments
                      </Button>
                      <Button
                        id={`button-send-certificate-${row.docId}`}
                        variant="contained"
                        sx={{
                          height: '32px !important',
                          padding: '6px 12px !important',
                          borderRadius:'6px',
                          width: '175px',
                          mr: 4,
                          '&.Mui-disabled': {
                            color: 'white !important',
                          },
                        }}
                        fullWidth
                        onClick={(event) => handleSendCertificate(event, row)}
                        disabled={!row?.detailedProgress?.course_completed}
                      >
                        Send Certificate
                      </Button>
                    </Box>
                    <Box
                      className='mobile-visible'
                      sx={{
                        display: 'none',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '30px',
                        p: 0
                      }}
                    >
                      <Button
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          minWidth: '0px',
                        }}
                        onClick={event => handleMobileMoreButton(event, row)}
                      >
                        <MoreVertIcon/>
                      </Button>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        { isLoadingResults ?
            <Box
              display='flex'
              width='100%'
              padding='1.5rem'
              alignItems='center'
              justifyContent='center'
            >
              <CircularProgress size={30} />
            </Box> :
            (results?.length === 0 ?
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 1,
                }}
              >
                <Typography
                  component='h6'
                  variant='h6'
                  fontWeight={400}
                  sx={{
                    fontSize: '14px',
                    color: 'gray',
                  }}
                >
                  No students found
                </Typography>
              </Box> :
              <></>
            )
        }
      </TableContainer>
      <MobileModal />
    </Box>
  )
}

export default CourseReview;
