import { FieldValue } from 'firebase/firestore';
import { UserStreaksRequest } from '../userStreaks/userStreaks.types';

export interface UserSignUpFormType {
  email: string;
  password: string;
}

export interface UserCreateProfileFormType {
  documentId: string;
  fullName: string;
  userName?: string;
  profilePhoto: any;
  profilePhotoPath?: string;
  profilePicUrl?: string;
  createdAt?: any;
  updatedAt?: any;
}

interface CommonUserType {
  id?: string;
  documentId?: string;
  email: string;
  password: string;
  userType: string;
  createdAt?: FieldValue | string;
  updatedAt?: FieldValue | string;
  userName: string;
  normalizedUserName?: string;
  topStreaksData?: UserStreaksRequest[];
  allStreaksData?: UserStreaksRequest[];
}

export interface UserFormType extends CommonUserType {
  whitelist_websites: string[];
  allow_widget: boolean;
  fullName: string;
  normalizedFullName: string;
  shortBio: string;
  twitterURL: string;
  linkedInURL: string;
  instagramURL: string;
  youtubeURL: string;
  tiktokURL: string;
  age: string;
  facebookAuthorised?: boolean;
  userWebPage: { web_link: string; disabled: boolean; is_indexed?: boolean }[];
  youtube_links?: { url: string }[];
  anythingElse: string;
  profilePhoto: any;
  userDocument: any[];
  documentPath?: any[];
  profilePhotoPath?: string;
  profilePicUrl?: string;
  profilePhotoURL?: string;
  bannerImagePath?: string;
  shareLink?: string;
  existingProfilePhoto?: string;
  existingProfilePicUrl?: string;
  existingUserDocument?: any[];
  deletedUserDocument?: any[];
  linkCount?: number;
  index?: string;
  occupation?: string;
  gender?: string;
  birthPlace?: string;
  residencePlace?: string;
  voiceSample?: any;
  voiceSamplePath?: string;
  existingVoiceSample?: string;
  voiceId?: string;
  verified?: boolean;
  bannerPhoto?: any;
  bannerPhotoPath?: string;
  bannerPhotoUrl?: string;
  existingBannerPhoto?: string;
  existingBannerUrl?: string;
  total_fans?: number;
  total_messages?: number;
  introMessage?: string;
  credits?: any[];
  customerId?: string;
  isSubscribed?: boolean;
  anonymous_chat_limit: number;
  subscription?: any;
  enterprise_name?: string;
}

export const IMMUTABLE_USER_KEYS = [
  'subscription',
  'isSubscribed',
  'anonymous_chat_limit',
  'allow_widget',
  'whitelist_websites',
]

export interface FollowerUserType extends CommonUserType {
  creators?: string[];
  fullName?: string;
  existingProfilePhoto?: string;
  existingProfilePicUrl?: string;
  profilePhoto?: any;
}

export type FormMode = 'create' | 'update' | 'profileComplete';
