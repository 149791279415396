import { Grid, Typography, Box, Button, Avatar } from "@mui/material";
import { grey } from '@mui/material/colors';
import SoopraIcon from '../../assets/images/soopra-icon.png';
import DefaultUserImage from '../../assets/images/default-avatar.png';
import { Link, useNavigate } from "react-router-dom";
import { COURSE_PRICES, STRIPE_PRODUCT_LOOKUP_KEYS } from "../../config/const";
import { useAuth } from '../../hooks/useAuth';

interface CourseItemType {
  courseItem: any;
  handleEnrollCourse: (event: any, item: any, item_id: any) => void;
  isEnrolled: boolean;
  disableButtons?: boolean;
}

const allowTakeCourse = process.env.REACT_APP_ALLOW_TAKE_COURSE === "true";

const CourseItem = (props: CourseItemType) => {
  const { courseItem, handleEnrollCourse, isEnrolled, disableButtons } = props || {};
  const navigate = useNavigate();
  const { authUser } = useAuth();

  const handleTakeCourse = (event: any) => {
    event.preventDefault();
    navigate(`/learn/${courseItem.course_id}`)
  }

  const EnrollOrTakeCourse = () => {
    return (
      !isEnrolled ?
        <Button
          id={`button-enroll-${courseItem.course_id}`}
          variant="contained"
          sx={{
            height: '32px !important',
            padding: '6px 12px !important',
            borderRadius:'6px',
            flex: 1,
            mr: 1,
          }}
          fullWidth
          onClick={(event) => {
            if (!disableButtons) {
              handleEnrollCourse(event, STRIPE_PRODUCT_LOOKUP_KEYS[courseItem.is_free_course ? "free_course" : courseItem.module_count], courseItem.course_id)
            }}
          }
        >
          Enroll
        </Button> :
      (allowTakeCourse || authUser?.beta_tester) ?
        <Button
          id={`button-take-course-${courseItem.course_id}`}
          variant="contained"
          sx={{
            height: '32px !important',
            padding: '6px 12px !important',
            borderRadius:'6px',
            flex: 1,
            mr: 1,
          }}
          fullWidth
          onClick={(event: any) => {
            if (!disableButtons) {
              handleTakeCourse(event)
            }
          }}
        >
          Take Course
        </Button>
         :
        <Button
          id={`button-enrolled-${courseItem.course_id}`}
          color="primary"
          variant="contained"
          sx={{
            height: '32px !important',
            padding: '6px 12px !important',
            borderRadius:'6px',
            flex: 1,
            mr: 1,
            '&.Mui-disabled': {
              color: '#353535 !important',
            },
          }}
          fullWidth
          disabled={true}
        >
          Enrolled
        </Button>
    )
  }

  return (
    <Grid item
      xs={12}
      sm={6}
      lg={4}
      xl={4}
      xxl={3}
      key={courseItem?.course_id}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '10px',
          boxShadow: '0px 0px 4px 0px #bbb',
          p: 2,
        }}
      >
        <Box>
          <img
            src={courseItem?.banner_image || courseItem?.creator_avatar || SoopraIcon}
            width='100%'
            style={{
              objectFit: 'cover',
              aspectRatio: 1.78,
            }}
            loading="lazy"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1,
            mt: 2
          }}
        >
          <Avatar
            alt={courseItem?.creator_name}
            src={courseItem?.creator_avatar || DefaultUserImage}
          />
          <Typography
            fontSize={'1rem'}
            fontWeight={400}
            ml={1.5}
            align='left'
            color={'black'}
            noWrap
          >
            {courseItem?.creator_name}
          </Typography>
        </Box>
        <Box>
          <Typography
            fontSize={'1.1rem'}
            fontWeight={700}
            mb={1}
            align='left'
            color={'black'}
            noWrap
          >
          {courseItem?.name || "Course Title"}
          </Typography>
          <Typography
            fontSize={'0.95rem'}
            fontWeight={400}
            align='left'
            color={'black'}
            noWrap
          >
            {[courseItem?.subject, courseItem?.grade, courseItem?.module_count + " Module Course"].join(", ")}
          </Typography>
          <Typography
            fontSize={'0.95rem'}
            fontWeight={400}
            mb={2}
            align='left'
            color={'black'}
          >
            {COURSE_PRICES[courseItem.is_free_course ? "free_course" : courseItem.module_count]}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
          <EnrollOrTakeCourse />
          <Link
            id={`href-course-details-${courseItem.course_id}`}
            to={!disableButtons ? `/courses/${courseItem.course_id}` : "#"}
            state={{courseItem: courseItem.modules ? courseItem : null}}
            style={{
              flex: 1,
              marginRight: 1
            }}
          >
            <Button
              id={`button-enroll-${courseItem.course_id}`}
              variant="contained"
              sx={{
                height: '32px !important',
                padding: '6px 12px !important',
                borderRadius:'6px',
                backgroundColor: 'white !important',
                border: '1px solid #009CDB',
                color: grey[900] + '!important',
                borderColor: grey[900] + '!important',
              }}
              fullWidth
            >
              Details
            </Button>
          </Link>
        </Box>
      </Box>
    </Grid>
  )
}

export default CourseItem;
