import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useProfileData } from '../../hooks/useProfileData';
import { useAuth } from '../../hooks/useAuth';
import UserProfileData from './userProfileData';
import { USER_TYPE } from '../../config/const';
import { getCreatorsFanStreaksData } from '../../utils/helper';
import UserStreaksData from './userStreaksData';

interface CreatorProfileProps {
  userName: string;
}

export default function CreatorProfile(props: CreatorProfileProps) {
  const { userName } = props;
  const [isLoading, setIsLoading] = React.useState(true);

  const navigate = useNavigate();
  const { authUser, checkUserProfileActivation } = useAuth();

  const { fetchProfileDataFromUserName, creatorProfileData, isLoadingProfile } =
    useProfileData();

  let activeSubscribe;
  React.useEffect(() => {
    if (userName) {
      checkValidUserAndFetchProfileData();
    }
    if (
      authUser?.userType === USER_TYPE.CREATOR &&
      authUser?.normalizedUserName === userName
    ) {
      getProfileActivationData();
    }
    return () => {
      activeSubscribe?.();
    };
  }, [userName, authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  const getProfileActivationData = async () => {
    activeSubscribe = await checkUserProfileActivation(authUser?.documentId);
  };

  const checkValidUserAndFetchProfileData = async () => {
    let checkCreatorActive = false;
    if (authUser?.normalizedUserName !== userName) {
      checkCreatorActive = true;
    }
    await fetchProfileDataFromUserName(
      userName,
      true,
      checkCreatorActive,
      navigate
    );
    setIsLoading(false);
  };

  const { data: topStreaksData, isLoading: isTopStreaksLoading } = useQuery({
    queryKey: [
      'creators-profile-top-streaks',
      creatorProfileData?.documentId,
      true,
    ],
    queryFn: getCreatorsFanStreaksData,
  });

  const { data: allStreaksData, isLoading: isAllStreaksLoading } = useQuery({
    queryKey: ['creators-all-streaks', creatorProfileData?.documentId],
    queryFn: getCreatorsFanStreaksData,
  });

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
      }}
    >
      {isLoading || isLoadingProfile ? (
        <Box
          mt={2}
          sx={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={30} />
        </Box>
      ) : (
        <>
          <UserProfileData
            authUser={authUser}
            profileData={creatorProfileData}
          />
          <UserStreaksData
            mode='profile'
            authUser={authUser}
            profileData={creatorProfileData}
            streaksData={{ topStreaksData, allStreaksData }}
            isTopStreaksLoading={isTopStreaksLoading}
            isAllStreaksLoading={isAllStreaksLoading}
          />
        </>
      )}
    </Box>
  );
}
