import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styles from './train.module.css';

// import CelebrationIcon from '../../svg/CelebrationIcon';
import LinearProgressWithLabel from '../../component/LinearProgressWithLabel';

interface ConversationType {
  conversationData: Record<string, any>;
  handleConversationClick: (topic: string) => void;
}

function ConversationItem(props: ConversationType) {
  const { handleConversationClick, conversationData } = props;
  const {
    topic,
    title,
    subTitle,
    cardContent,
    progress,
    isCurrent,
    isCompleted,
    isGenerating,
  } = conversationData || {};

  return (
    <Box
      key={topic}
      display='flex'
      className={`${styles.level} ${
        isCurrent
          ? styles.currentLevel
          : isCompleted
          ? styles.completedLevel
          : ''
      }`}
      sx={{border: 'none', width: 1, p: 0}}
    >
      <Box flexDirection='column' display='flex' rowGap={0.5} sx={{width: 1}}>
        <Typography variant={'h6'}
          fontWeight={600}
          color={isCurrent || isCompleted ? 'black' : 'gray'}
          title={title}
        >
          {title}
        </Typography>

        <Typography
          variant={'subtitle2'}
          fontWeight={500}
          color={isCurrent || isCompleted ? 'black' : 'gray'}
          title={subTitle}
        >
          {subTitle}
        </Typography>
        <Typography
          variant={'subtitle2'}
          fontWeight={500}
          color={isCurrent || isCompleted ? 'black' : 'gray'}
          title={cardContent}
        >
          {cardContent}
        </Typography>
        <Grid container justifyContent='center' sx={{mt: 1}}>
        {isCompleted ? (
            <Button
              id={`button-start-again-${topic}`}
              variant='outlined'
              onClick={() => handleConversationClick(topic)}
              color='mainBlue'
              size='large'
              sx={{
                px: 4,
                borderRadius: 3,
                '&:hover': {
                  boxShadow: 'none',
                },
                boxShadow: 'none',
                fontWeight: 600,
                width: '100%',
              }}
            >
              Start Again
            </Button>
          ) : (
            <Button
              id={`button-start-${topic}`}
              variant='contained'
              onClick={() => handleConversationClick(topic)}
              disabled={!isCurrent}
              color='mainBlue'
              size='large'
              sx={{
                px: 4,
                borderRadius: 3,
                border: 'none',
                boxShadow: 'none',
                fontWeight: 600,
                '&:hover': {
                  boxShadow: 'none',
                },
                width: '100%',
              }}
            >
              {isGenerating ? 'Level Generating...' : (isCurrent ? 'Start' : 'Locked')}
            </Button>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default React.memo(ConversationItem);
