import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';

import styles from './train.module.css';

interface LevelTwoQuestionItemType {
  levelQuestionItem: Record<string, any>;
  keyIndex: string;
  formErrors: Record<string, any>;
  formSubmitting: boolean;
  isLastItem?: boolean;
  saveUserQuestionAnswerData: (
    question: string,
    answerItem: string,
    answerType: string,
    isCustomAnswer?: boolean
  ) => void;
}

const ANSWER_TYPES = {
  TRUTH: 'truth',
  LIES: 'lies',
  OTHER: 'other',
};

function LevelTwoQuestionItem(props: LevelTwoQuestionItemType) {
  const {
    keyIndex,
    levelQuestionItem,
    formErrors,
    formSubmitting,
    isLastItem,
    saveUserQuestionAnswerData,
  } = props;
  const { userAnswers, userAnswerType, truth, lies } = levelQuestionItem || {};

  return (
    <Box
      key={keyIndex}
      display='flex'
      flexDirection='column'
      flex={1}
      width='100%'
      px={6}
      py={2}
      className={!isLastItem && styles.levelQuestionItem}
    >
      {/* <Typography variant={'subtitle1'} fontWeight={500} mb={1}>
        {question}
      </Typography> */}
      <FormControlLabel
        control={
          <Checkbox
            checked={userAnswers === truth || false}
            onChange={() =>
              saveUserQuestionAnswerData(truth, truth, ANSWER_TYPES?.TRUTH)
            }
            color='primary'
            disabled={formSubmitting}
          />
        }
        label={
          <Typography variant={'subtitle2'} fontWeight={500} title={truth}>
            {truth}
          </Typography>
        }
      />
      {lies?.length >= 1 &&
        lies?.map((optionItem, index) => (
          <FormControlLabel
            key={`truth-${index}`}
            control={
              <Checkbox
                checked={userAnswers === optionItem || false}
                onChange={() =>
                  saveUserQuestionAnswerData(
                    truth,
                    optionItem,
                    ANSWER_TYPES?.LIES
                  )
                }
                color='primary'
                disabled={formSubmitting}
              />
            }
            label={
              <Typography
                variant={'subtitle2'}
                fontWeight={500}
                title={optionItem}
              >
                {optionItem}
              </Typography>
            }
          />
        ))}

      <FormControlLabel
        control={
          <Checkbox
            checked={userAnswerType === ANSWER_TYPES?.OTHER || false}
            onChange={() =>
              saveUserQuestionAnswerData(truth, '', ANSWER_TYPES?.OTHER)
            }
            color='primary'
            disabled={formSubmitting}
          />
        }
        label={
          <Typography variant={'subtitle2'} fontWeight={500} title={'Other'}>
            {'Other'}
          </Typography>
        }
      />

      {userAnswerType === ANSWER_TYPES?.OTHER && (
        <TextField
          key={`${keyIndex}-${truth}`}
          className={styles.trainInputs}
          style={{ marginBottom: 2 }}
          variant='outlined'
          fullWidth
          disabled={formSubmitting}
          id={`text-input-other-${keyIndex}`}
          name='userAnswers'
          value={userAnswers}
          onChange={(e) =>
            saveUserQuestionAnswerData(
              truth,
              e?.target?.value,
              ANSWER_TYPES?.OTHER
            )
          }
        />
      )}
    </Box>
  );
}

export default React.memo(LevelTwoQuestionItem);
