import * as React from 'react';
import Box from '@mui/material/Box';

import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
} from 'firebase/firestore';
import { firestore } from '../../utils/firebase';

export default function MigrationScript() {
  const [scriptRunning, setScriptRunning] = React.useState<string | null>(null);

  React.useEffect(() => {
    // duplicateUsersCollection();
    // updatedUsersCollection();
  }, []);

  // const duplicateCollection = async () => {
  //   setDuplicateScriptRunning('start');
  //   try {
  //     const messageQuery = query(collection(firestore, 'messages'));
  //     const messagesData = await getDocs(messageQuery);
  //     if (messagesData?.docs?.length >= 1) {
  //       messagesData?.docs.map(async (messageDoc, index) => {
  //         await addDoc(
  //           collection(firestore, 'messages-copy'),
  //           messageDoc?.data()
  //         );
  //       });
  //     }
  //   } catch (error) {
  //     console.log('error', error);
  //   } finally {
  //     setDuplicateScriptRunning('finish');
  //   }
  // };

  // const updatedMessagesCollection = async () => {
  //   setDuplicateScriptRunning('start');
  //   try {
  //     const messageQuery = query(collection(firestore, 'messages'));
  //     const messagesData = await getDocs(messageQuery);
  //     if (messagesData?.docs?.length >= 1) {
  //       messagesData?.docs.map(async (messageDoc, index) => {
  //         const { sentTime } = messageDoc?.data();
  //         const documentId = messageDoc?.id;
  //         if (typeof sentTime === 'string') {
  //           const updatedMessageItem = {
  //             ...messageDoc?.data(),
  //             oldSentTime: sentTime,
  //             ...(sentTime && {
  //               sentTime: Timestamp.fromDate(new Date(sentTime)),
  //             }),
  //           };

  //           await updateDoc(
  //             doc(firestore, 'messages', documentId),
  //             updatedMessageItem
  //           );
  //         }
  //       });
  //     }
  //   } catch (error) {
  //     console.log('error', error);
  //   } finally {
  //     setDuplicateScriptRunning('finish');
  //   }
  // };

  // @ts-ignore
  // const duplicateUsersCollection = async () => {
  //   setScriptRunning('start');
  //   try {
  //     const usersQuery = query(collection(firestore, 'users'));
  //     const usersData = await getDocs(usersQuery);
  //     if (usersData?.docs?.length >= 1) {
  //       usersData?.docs.map(async (userDoc, index) => {
  //         await addDoc(collection(firestore, 'users-copy'), userDoc?.data());
  //       });
  //     }
  //   } catch (error) {
  //     console.log('error', error);
  //   } finally {
  //     setScriptRunning('finish');
  //   }
  // };

  // // @ts-ignore
  // const updatedUsersCollection = async () => {
  //   setScriptRunning('start');
  //   try {
  //     const usersQuery = query(collection(firestore, 'users'));
  //     const usersData = await getDocs(usersQuery);
  //     if (usersData?.docs?.length >= 1) {
  //       usersData?.docs.map(async (userDoc, index) => {
  //         const { fullName } = userDoc?.data();
  //         const documentId = userDoc?.id;
  //         if (fullName && fullName?.trim()) {
  //           const updatedItem = {
  //             ...userDoc?.data(),
  //             normalizedFullName: fullName?.trim()?.toLowerCase(),
  //           };
  //           console.log('updatedItem', updatedItem);

  //           await updateDoc(doc(firestore, 'users', documentId), updatedItem);
  //         }
  //       });
  //     }
  //   } catch (error) {
  //     console.log('error', error);
  //   } finally {
  //     setScriptRunning('finish');
  //   }
  // };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      {scriptRunning === 'start' && 'Record Duplication Start'}
      {scriptRunning === 'finish' && 'Record Duplication Finish'}
    </Box>
  );
}
