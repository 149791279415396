import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import { debounce, IconButton } from '@mui/material/';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { useAuth } from '../../hooks/useAuth';
import AuthUserProfile from './authUserProfile';
import FanCreatorItem from './fanCreatorItem';
import CreateMimioContainer from './createMimioContainer';
import { USER_TYPE } from '../../config/const';
import styles from './fanCreatorList.module.css';
import { getCreatorsList, getSortedCreatorList, getCorporateAccounts } from '../../utils/api';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import { grey } from '@mui/material/colors';
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import { SortByContext } from '../../context/SortByContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slider from "react-slick";

const PAGE_LIMIT = 1000;

//create mimio feature to show/hide create my mimio section
const createMIMIOFeature =
  process.env.REACT_APP_ENABLE_CREATE_MIMIO_FEATURE === "true";

const enableCreatorSortBy =
  process.env.REACT_APP_ENABLE_CREATOR_SORT_BY === "true";


export default function FanCreatorList() {
  const { enterpriseName } = useParams();
  const searchValue = React.useRef<string>('');
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const searchTimer = React.useRef<any>(0);
  const isLoading = React.useRef<boolean>(false);
  const { authUser } = useAuth();
  const [creatorsData, setCreatorsData] = React.useState([]);
  const [showSortByMenu, setShowSortByMenu] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>(null);
  const currentPage = React.useRef<number>(1);
  const lastVisibleDoc = React.useRef<any>();
  const fullyLoaded = React.useRef<boolean>(false);
  const { sortBy } = React.useContext(SortByContext);
  const isMobile = React.useRef<boolean>(false);
  const eventTracker = useAnalyticsEventTracker();
  const bottom = React.useRef(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    checkMobile();

    window.addEventListener('resize', checkMobile, false);

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (searchValue.current?.length) {
          creatorSearch();
        } else {
          handleFetchData();
        }
      }
    });
    observer.observe(bottom.current);

    return () => {
      window.removeEventListener('resize', checkMobile, false);
      observer.disconnect();
    };
  }, [creatorsData])

  React.useEffect(() => {
    // refetch data any time sortBy is changed
    if (authUser?.uid && !isLoading.current) {
      currentPage.current = 1;
      fullyLoaded.current = false;
      handleFetchData();
    }
  }, [sortBy.current])

  React.useEffect(() => {
    // handle searchValue changes
    if (searchValue.current?.length && !isLoading.current) {
      currentPage.current = 1;
      fullyLoaded.current = false;
      lastVisibleDoc.current = null;
      setCreatorsData([])
      creatorSearch();
    } else {
      // default to sortBy list on empty searchValue
      if (authUser?.uid && !isLoading.current) {
        currentPage.current = 1;
        fullyLoaded.current = false;
        handleFetchData();
      }
    }
  }, [searchValue.current])

  let checkMobile = () => {
    if (window.screen.availWidth < 900) {
      isMobile.current = true;
    } else {
      isMobile.current = false;
    }
  }

  let creatorSearch = async () => {
    if (!fullyLoaded.current && !isLoading.current) {
      isLoading.current = true;

      getCreatorsList(authUser?.uid, searchValue.current || '', currentPage.current, PAGE_LIMIT)
        .then(async (res) => {
          if (res.ok) {
            await res.json().then(data => {
              if (data.data.length === 0) {
                fullyLoaded.current = true;
              }

              let redoSearch = false;

              // make sure the search at least fills the page
              setCreatorsData(prevData => {
                if (prevData.length + data.data.length < 6) {
                  redoSearch = true;
                }
                return [...prevData, ...data.data]
              })
              currentPage.current++;
              isLoading.current = false;

              if (redoSearch && data.data.length) creatorSearch();
            })
          } else {
            isLoading.current = false;
            throw res.error
          }
        })
    }
  };

  const handleSearch = (event: any) => {
    const newValue = event?.target?.value?.toLowerCase();
    if (searchTimer?.current) {
      clearTimeout(searchTimer.current);
      searchTimer.current = 0;
    }

    searchTimer.current = setTimeout(() => {
      searchValue.current = newValue;
      searchTimer.current = 0;
      setRefresh(!refresh)
    }, 250);
  };

  const handleFetchData = () => {
    if (!fullyLoaded.current && !isLoading.current) {
      isLoading.current = true;
      setRefresh(curState => !curState);
      if (currentPage.current === 1) {
        setCreatorsData([]);
      }
  
      
      const sortBy = 'aToz';  
      const fetchData = getCorporateAccounts("sambatv", currentPage.current, PAGE_LIMIT, sortBy);
  
      fetchData.then(resp => {
        if (resp.ok) {
          return resp.json();
        } else {
          isLoading.current = false;
          throw new Error('Failed to fetch corporate accounts');
        }
      }).then(data => {
  
        currentPage.current += 1;
        if (!data.data.length || sortBy === 'aToz') {
          fullyLoaded.current = true;
        }
        isLoading.current = false;
  
        // Log the creatorsData being set
        setCreatorsData(prevData => {
          return [...prevData, ...data.data];
        });
      }).catch(err => {
        isLoading.current = false;
        fullyLoaded.current = true;
        setRefresh(curState => !curState);
      });
    }
  };

  const CourseBanner = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
    return (
      <>
        
      </>
    );
  };
  
  
  return (
    <Box display='flex' flex={1} >
      <Box
        width='100%'
        display='flex'
        flexDirection='column'
        alignItems='center'
        textAlign='center'
      >
        <AuthUserProfile authUserData={authUser} />
        {createMIMIOFeature && authUser?.userType === USER_TYPE.FOLLOWER && (
          <Box className={styles.createContainerMob}>
            <CreateMimioContainer />
          </Box>
        )}

        <Grid container>

          {creatorsData?.length >= 1 ? (
            <Box width='100%' sx={{ pb: 2, paddingTop: 2 }}>
              <Grid container spacing={2}>
                {creatorsData?.map((creatorItem, i) => (
                  <FanCreatorItem
                    creatorItem={creatorItem}
                    key={creatorItem?.creatorId}
                  />
                ))}
              </Grid>
            </Box>
          ) : isLoading.current && creatorsData?.length === 0 ? (
            <Box
              display='flex'
              width='100%'
              padding='1.5rem'
              alignItems='center'
              justifyContent='center'
            >
              <CircularProgress size={30} />
            </Box>
          ) : (
            <Box
              mt={2}
              rowGap={2}
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Avatar
                sx={{
                  width: 80,
                  height: 80,
                }}
              />
              <Typography
                component='h5'
                variant='body2'
                fontSize='1rem'
                fontWeight={500}
              >
                No persona found
              </Typography>
            </Box>
          )}
        </Grid>
        <div className="custom-bottom" ref={bottom}/>
      </Box>
    </Box>
  );
}
