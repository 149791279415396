import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { Link } from 'react-router-dom';
import { auth } from '../../utils/firebase';
import { firestore } from '../../utils/firebase';
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  doc,
  query,
  where,
} from 'firebase/firestore';
import { toast } from 'react-toastify';

const Settings = () => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [emailOptIn, setEmailOptIn] = React.useState<boolean>(false);
  const link = 'https://www.soopra.ai/';

  useEffect(() => {
    const fetchEmailOptIn = async () => {
      try {
        const id = auth.currentUser.uid;
        const userDocRef = doc(firestore, 'users', id);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();

          if (userData && userData.emailOptIn !== undefined) {
            setEmailOptIn(userData.emailOptIn);
          }
        } else {
          console.log('User document does not exist in Firestore');
        }
      } catch (error) {
        console.error('Error fetching emailOptIn:', error);
      }
    };

    fetchEmailOptIn();
  }, []);

  const handleSubscribe = async () => {
    setShowConfirmationDialog(true);
  };

  const cancelSubscribe = () => {
    setShowConfirmationDialog(false);
  };

  const confirmSubscribe = async () => {
    try {
      const email = auth.currentUser.email;

      const subscribersCollection = collection(firestore, 'users');
      const q = query(
        subscribersCollection,
        where('email', '==', email.trim())
      );
      const querySnapshot = await getDocs(q);

      const newOptInValue = !emailOptIn;

      if (querySnapshot.empty) {
        await addDoc(subscribersCollection, { email, optIn: true });
      } else {
        querySnapshot.forEach(async (docSnapshot) => {
          const subscriberDocRef = doc(subscribersCollection, docSnapshot.id);
          await updateDoc(subscriberDocRef, { emailOptIn: newOptInValue });
        });
      }

      setShowConfirmationDialog(false);
      if (!emailOptIn) {
        toast.success('Congratulations, you are subscribed!');
      } else {
        toast.error('You are no longer subscribed');
      }
      setEmailOptIn((prevEmailOptIn) => !prevEmailOptIn);
    } catch (error) {
      console.error('Error subscribing user:', error);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '24px',
          position: 'relative',
          justifyContent: 'center',
        }}
      >
        <h2>Settings</h2>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '16px',
            borderRadius: '4px',
            padding: '8px',
            width: '50%',
          }}
        >
          <Typography>
            Receive email communications from{' '}
            <a id='href-soopra' target='_blank' style={{ color: 'blue' }} href={link}>
              Soopra.ai
            </a>
          </Typography>
          <Box sx={{ marginBottom: '15px', width: '100%', maxWidth: '200px' }}>
            <Button
              id='button-subscribe-unsubscribe'
              variant='contained'
              style={{
                backgroundColor: emailOptIn ? '#f55442' : '#48c936',
                color: 'white',
                width: '100%',
              }}
              onClick={handleSubscribe}
            >
              {emailOptIn ? 'Unsubscribe' : 'Subscribe'}
            </Button>
          </Box>

          <Box sx={{ marginBottom: '8px', width: '100%', maxWidth: '200px' }}>
            <Link to='/logout-user'>
              <Button
                id='button-logout'
                variant='contained'
                color='primary'
                style={{
                  width: '100%',
                }}
              >
                Sign Out
              </Button>
            </Link>
          </Box>
        </Box>

        <Dialog open={showConfirmationDialog} onClose={cancelSubscribe}>
          <DialogTitle>Confirm Subscription</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {emailOptIn ? (
                <Typography>
                  Are you sure you want to unsubscribe from{' '}
                  <a id='href-soopra' target='_blank' style={{ color: 'blue' }} href={link}>
                    {' '}
                    Soopra.ai
                  </a>
                  's communications?
                </Typography>
              ) : (
                <Typography>
                  Are you sure you want to subscribe from{' '}
                  <a id='href-soopra' target='_blank' style={{ color: 'blue' }} href={link}>
                    {' '}
                    Soopra.ai
                  </a>
                  's communications?
                </Typography>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button size='small' id='button-cancel-subscribe' onClick={cancelSubscribe} color='primary'>
              Cancel
            </Button>
            <Button size='small' id='button-subscribe-unsubscribe' onClick={confirmSubscribe} color='primary'>
              {emailOptIn ? 'Unsubscribe' : 'Subscribe'}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default Settings;
