import React, { useRef, useState, useEffect } from 'react'
import ChatScreen from '../userChat'
import ChatIcon from '../../svg/ChatIcon'
import { Box, Fab } from '@mui/material'
import PoweredBySoopra from '../../svg/PoweredBySoopraIcon'
import VoiceOffIcon from '../../svg/VoiceOffIcon'
import './chat-widget.css'
import VoiceOnIcon from '../../svg/VoiceOnIcon'
import { useLocation, useParams } from 'react-router-dom'
import { useProfileData } from '../../hooks/useProfileData'
import { Timestamp } from 'firebase/firestore'

const ChatWidgetScreen: React.FC = () => {
  const [handleSignInModal, setHandleSignInModal] = useState<boolean>(false);
  const [allowWidget, setAllowWidget] = useState<boolean> (true);
  const [windowHostname, setWindowHostname] = useState<string> ('')
  const [isWidgetVoiceMode, setWidgetVoiceMode] = useState<boolean> (false)
  const [deviceType, setDeviceType] = useState<string>('desktop')
  const [showChat, setShowChat] = useState<boolean>(false)
  const [showVoiceResponseMenu, setShowVoiceResponseMenu] = useState<boolean>(false)
  const [selectedVoiceOption, setSelectedVoiceOption] = useState<string>('Voice Off')
  const [hasOpenedWidget, setHasOpenedWidget] = useState<boolean>(false);

  const chatContainerRef = useRef(null);

  const location = useLocation()
  const {userName: userNameParam }= useParams()
  const userName = userNameParam?.toLowerCase()

  const {
    fetchProfileDataFromUserName,
    creatorProfileData,
  } = useProfileData();

  const voiceOptions = [creatorProfileData?.fullName, 'Standard', 'Voice Off']

  // Set styles to app-main
  useEffect(() => {
    document.getElementById('app-main').style.height = '100%';
    document.getElementById('app-main').style.marginTop = '0px';
  }, [])

  useEffect(() => {
    if (userName) {
      loadCreatorInfo();
    }
  }, [userName]);

  useEffect(()=> {
    const getQueryParam = (param) => {
      const urlParams = new URLSearchParams(location.search);
      return urlParams.get(param);
    };
    // Get deviceType from URL
    setDeviceType(getQueryParam('deviceType'));
    const hostURL = window.location.ancestorOrigins[0] ? new URL(window.location?.ancestorOrigins[0]) : ''
    setWindowHostname(hostURL !== '' && hostURL.hostname)
  }, [location.search])

  //Check if allow_widget is true, then check if website is whitelisted
  useEffect(() => {
    if (creatorProfileData?.subscription?.chat_widget) {
      let chatWidgetData = creatorProfileData.subscription.chat_widget;
      let currentTimeStamp = Timestamp.fromDate(new Date());
      if (chatWidgetData.expiration_date > currentTimeStamp) {
        if ([...chatWidgetData.whitelist_websites].includes(windowHostname)) {
          setAllowWidget(true)
        }
      } else {
        console.log('widget not allowed')
        if (allowWidget) {
          setAllowWidget(false)
        }
      }
    }
    return;
  }, [creatorProfileData, windowHostname])

  useEffect(() => {
    if(allowWidget) {
      if(showChat) {
        if(handleSignInModal) sendSignInSignUpModalDimsToParent();
        else if (isWidgetVoiceMode) sendWidgetVoiceModeDimsToParent();
        else sendWidgetDimsToParent();
      }
      else sendButtonDimsToParent();
    }
    else {
      sendNoneDimsToParent();
    }
  }, [allowWidget, windowHostname, isWidgetVoiceMode]);

  useEffect(() => {
    if (handleSignInModal) {
      sendSignInSignUpModalDimsToParent();

      const observer = new MutationObserver(sendSignInSignUpModalDimsToParent);
      observer.observe(chatContainerRef.current, {
          attributes: true,
          childList: true,
          subtree: true
      });

      return () => {
          observer.disconnect();
      };
    }
  }, [handleSignInModal]);

  const sendButtonDimsToParent = () => {
    const height = '56px';
    const width = '56px';
    const borderRadius = '50%'
    const bottom ='16px'
    const right= '16px'
    window.parent.postMessage({ height: height, width: width, borderRadius: borderRadius, bottom:bottom, right:right}, '*');
  };

  const sendWidgetDimsToParent = () => {
    if (deviceType === 'desktop') {
      const height = '362px';
      const width = '422px';
      const borderRadius = '10px'
      const bottom ='16px'
      const right= '16px'
      window.parent.postMessage({ height: height, width: width, borderRadius: borderRadius, bottom:bottom, right:right}, '*');
    }
    else {
      const height = '100%';
      const width = '100%';
      const borderRadius = '0px'
      const bottom ='0px'
      const right= '0px'
      window.parent.postMessage({ height: height, width: width, borderRadius: borderRadius, bottom:bottom, right:right}, '*');
    }
  };

  const sendSignInSignUpModalDimsToParent = () => {
    if (deviceType === 'desktop') {
      const height = '515px';
      const width = '422px';
      const borderRadius = '10px'
      const bottom ='16px'
      const right= '16px'
      window.parent.postMessage({ height: height, width: width, borderRadius: borderRadius, bottom:bottom, right:right}, '*');
    }
    else {
      const height = '100%';
      const width = '100%';
      const borderRadius = '0px'
      const bottom ='0px'
      const right= '0px'
      window.parent.postMessage({ height: height, width: width, borderRadius: borderRadius, bottom:bottom, right:right}, '*');
    }
  }

  const sendWidgetVoiceModeDimsToParent = () => {
    if (deviceType === 'desktop') {
      const height = '515px';
      const width = '422px';
      const borderRadius = '10px'
      const bottom ='16px'
      const right= '16px'
      window.parent.postMessage({ height: height, width: width, borderRadius: borderRadius, bottom:bottom, right:right}, '*');
    }
    else {
      const height = '100%';
      const width = '100%';
      const borderRadius = '0px'
      const bottom ='0px'
      const right= '0px'
      window.parent.postMessage({ height: height, width: width, borderRadius: borderRadius, bottom:bottom, right:right}, '*');
    }
  }

  const sendNoneDimsToParent = () => {
    const height = '0%';
    const width = '0%';
    const borderRadius = '0px'
    const bottom ='0px'
    const right= '0px'
    window.parent.postMessage({ height: height, width: width, borderRadius: borderRadius, bottom:bottom, right:right}, '*');
  }

  //Load creator information from userName
  const loadCreatorInfo = async () => {
    await fetchProfileDataFromUserName(userName, true, true);
  };

  const handleButtonWidget = () => {
    sendWidgetDimsToParent(); //since widget is going to show up, send widget dims to parent
    setWidgetVoiceMode(false);
    setShowChat(!showChat);
    if (!hasOpenedWidget) setHasOpenedWidget(true);
  }

  const handleScreenWidget = () => {
    sendButtonDimsToParent();//since button is going to show up, send button height to parent
    setShowChat(!showChat)
  }

  const toggleVoiceResponseMenu = () => {
    setShowVoiceResponseMenu(!showVoiceResponseMenu)
  }

  const handleSelectedVoiceOption = (e) => {
    setSelectedVoiceOption(e)
  }

  const onClickOutsideVoiceMenuListener = () => {
    setShowVoiceResponseMenu(false)
    document.removeEventListener("click", onClickOutsideVoiceMenuListener)
  }

  const VoiceMenuBar = () => {
    return(
      <div className='voice-menu-container'>
        <div className='voice-menu-wrapper'
          onMouseLeave={() => {
                        document.addEventListener("click", onClickOutsideVoiceMenuListener)
                        }}>
          <Box display='flex' flexDirection='row' justifyContent='space-between' gap='15px'>
            <div><strong>Choose Menu</strong></div>
            <div>
              <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 6L6 1L11 6" stroke="#65635F" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </Box>
          {voiceOptions.map((option,i) => {
            return(
              <div>
                {option === 'Voice Off' ? <hr style={{border: '0.1px solid #dbdbdb'}}/> : <></>}
                <div style={{display:'flex', flexDirection:'row', gap:'2px', justifyContent:'center', alignItems:'center'}}>
                  <div className={`voice-menu-dot${option === selectedVoiceOption?'-selected':''}`}>
                  </div>
                  <div className={`voice-menu-option${option === selectedVoiceOption?'-selected':''}`} key={i} onClick={() => handleSelectedVoiceOption(option)}>
                    {option}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    allowWidget && <>
      <div className='chat-widget' id='chat-widget'>
        {hasOpenedWidget ?
          <>
            <Box
              className={`chat-widget-screen ${handleSignInModal ? 'sign-in' : 'chat'}-screen-height`}
              id='chat-container'
              ref={chatContainerRef}
              sx={{
                display: showChat ? 'flex' : 'none !important'
              }}
            >
              <ChatWidgetMenuBar
                toggleVoiceResponseMenu={toggleVoiceResponseMenu}
                showVoiceResponseMenu={showVoiceResponseMenu}
                VoiceMenuBar={VoiceMenuBar}
                selectedVoiceOption={selectedVoiceOption}
                handleScreenWidget={handleScreenWidget}
              />
              <ChatScreen
                handleSignInModal={handleSignInModal}
                setHandleSignInModal={setHandleSignInModal}
                isWidget
                setWidgetVoiceMode={setWidgetVoiceMode}
              />
            </Box>
            <Fab
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                backgroundColor: '#ffffff',
                display: showChat ? 'none !important' : 'auto'
              }}
              size='large'
              className='chat-btn'
              id='chat-container'
              ref={chatContainerRef}
              onClick={handleButtonWidget}
            >
              <Box className='chat-btn-svg'
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <ChatIcon/>
              </Box>
            </Fab>
          </>
          :
          <Fab
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              backgroundColor: '#ffffff'
            }}
            size='large'
            className='chat-btn'
            id='chat-container'
            ref={chatContainerRef}
            onClick={handleButtonWidget}
          >
            <Box className='chat-btn-svg'
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <ChatIcon/>
            </Box>
          </Fab>
        }
      </div>
    </>
  )

}


function ChatWidgetMenuBar({toggleVoiceResponseMenu, showVoiceResponseMenu, VoiceMenuBar, selectedVoiceOption, handleScreenWidget}) {
  return(
    <div className='chat-widget-menu-bar'
      style={{
        backgroundColor: 'white',
        padding: '10px 20px 0px 20px',
        zIndex: 3
      }}>
      <div style={{display: 'flex', flexDirection:'row', justifyContent: 'space-between', borderBottom: '1px solid #EEEEEE'}}>
        <div style={{}}><PoweredBySoopra/></div>
        <div className='chat-widget-btn-group'>
          <div className='chat-widget-voice-btn-container' onClick={toggleVoiceResponseMenu}>
            {showVoiceResponseMenu ?
              <>
                <VoiceMenuBar/>
              </>
              :
              <div className='voice-btn'>
              {selectedVoiceOption ===  'Voice Off' ? <VoiceOffIcon/> :
              <VoiceOnIcon/>}
              </div>}
          </div>
          <div className='chat-widget-close-btn-container'>
            <div className='chat-widget-close-btn' onClick={handleScreenWidget}>
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L4 4M7 7L4 4M4 4L7 1M4 4L1 7" stroke="#36332D" strokeLinecap="round"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatWidgetScreen
