type WindowWithDataLayer = Window & {
  gtag: Function;
};

declare const window: WindowWithDataLayer;

const useAnalyticsEventTracker = () => {
  return (action = "action") => window.gtag('event', action);
}

export default useAnalyticsEventTracker;
